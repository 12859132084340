import React, { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ssiApi from '../../ssiApi.js';
import Navbar from '../../Navbar';
import Footer from '../Footer/Footer';
import './admin.css';
import axios from 'axios';
import studentPhoto from '../../img/studentIcon.png'
import DocumentTable from './DocumentTable';
import DocumentViewer from './DocumentViewer.js';
import ReactDOMServer from 'react-dom/server';
import ReactDOM from 'react-dom'; // Import ReactDOM
import adminBack from '../../img/auditBanerImg.jpeg'
import CertTable from './certPage.js'

import { JsonToTable } from "react-json-to-table";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocation, faPhone, faEnvelope, faEllipsisVertical, faIdBadge, faFilePdf, faImage, faShare, faQuestionCircle, faArrowDown, faCaretDown, faPen, faUser, faMedal, faArrowUpRightFromSquare, faCheckCircle, faBitcoinSign, faBell, faHome, faList, faCalendar, faRightFromBracket, faArrowLeft, faEye, faCheck, faCross, faClose } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagramSquare, faLinkedin, faYoutubeSquare, faTwitterSquare, faWhatsapp, faFacebookSquare, faWhatsappSquare, faLinkedinIn, faTwitter, faEmber, faBitcoin } from '@fortawesome/free-brands-svg-icons'


const ssiBaseUrl = "https://ssi-test.myearth.id";
const authorizationKey = "01a41742-aa8e-4dd6-8c71-d577ac7d463c";

const privateKey = "6mf4ZKu2ts0V4Dr0sS+YbXYveZOyDezzTJUqa5Ls29BHN4jBTY5we/WW0CgmuNGb3PBvdqqJPhDF2YCMnF4faw==";
const publicKey = "RzeIwU2OcHv1ltAoJrjRm9zwb3aqiT4QxdmAjJxeH2s=";
const issuerDID = "did:earthid:testnet:2utZGEuzWe8ds5Jaxd7KdMD7bwKMm8QiRKnS2t4Q8Dve;earthid:testnet:fid=0.0.15057499";
const userDID = "did:earthid:testnet:9WzSyPSBkbUQVktjGxmvKAzbTNJCs1PFqo2fQ6BnuHiE;earthid:testnet:fid=0.0.15057499";

const MySwal = withReactContent(Swal);

const SidebarButton = ({ label, icon, onClick, isSelected, customClassName }) => {
    return (
        <button
            className={`sidebarbtn ${isSelected ? 'selected' : ''} ${customClassName}`}
            onClick={onClick}
        >
            <FontAwesomeIcon className="bellIcon" icon={icon} />
            {label}
        </button>
    );
};

const Admin = () => {
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedButton, setSelectedButton] = useState('Home');
    const [selectedUserDetails, setSelectedUserDetails] = useState(null);
    const [showUserList, setShowUserList] = useState(true);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [selectedMetadata, setSelectedMetadata] = useState(null);
    const [selectedDocHash, setSelectedDocHash] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [numPages, setNumPages] = useState(0);
    const [users, setUsers] = useState([]);
    const [pdfs, setPdfs] = useState([]);
    const [vcs, setVcs] = useState([]);
    const [trainingvcs, setTrainingVCs] = useState([]);
    const [professionalvcs, setProfessionalVCs] = useState([]);
    const [onBoardingvcs, setOnBoardingVcs] = useState([]);
    const [idvcs, setIdVcs] = useState([]);
    const [uniqueReqType, setUniqueReqType] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const [loading, setLoading] = useState(false); // Added loading state
    const [selectAll, setSelectAll] = useState(false); // New state variable for "Select All" checkbox
    //const [selectedMenuButton, setSelectedMenuButton] = useState('Home'); // Default to 'Home'


    // useEffect(() => {
    //     fetchVcs();
    // }, []);

    const fetchUserList = async () => {
        try {
            const response = await fetch('https://bankdb.myearth.id/api/vcnew/DocumentVc/companies');
            const responseData = await response.json();
            console.log('Response', responseData)
            if (!Array.isArray(responseData.response)) {
                throw new Error('Invalid response data format');
            }

            const uniqueUserNames = [...new Set(responseData.response.map((response) => response))];
console.log('Company Names', uniqueUserNames)
            setUsers(uniqueUserNames);
        } catch (error) {
            console.error('Error fetching user list:', error);
        }
    };

    const fetchDocs = async (userName) => {
        try {
            //setLoading(true); // Set loading state to true

            // Fetch the document CIDs for the given user
            const fetchDocsResponse = await fetch(`https://bankdb.myearth.id/api/docnew/company/${userName}`);
            const fetchDocsData = await fetchDocsResponse.json();
            console.log('User All Docs',fetchDocsData.response);

            if (!Array.isArray(fetchDocsData.response)) {
                throw new Error('Invalid response data format');
            }

            // Prepare the request body with the CIDs
            const requestBody = {
                response: fetchDocsData.response,
            };
            console.log(requestBody);

            // Fetch the files based on the CIDs
            // const fetchFilesResponse = await fetch('https://bankdb.myearth.id/api/doc/retrieveFiles', {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify(requestBody),
            // });

            // if (!fetchFilesResponse.ok) {
            //     throw new Error('Failed to fetch files');
            // }

            // const fetchFilesText = await fetchFilesResponse.json();
            // console.log(fetchFilesText.fileNames)

            // Set the array of document URLs to the state variable
            setPdfs(requestBody);

            setLoading(false); // Stop loading
        } catch (error) {
            console.error('Error fetching documents:', error);
            setLoading(false); // Stop loading
            // Display Swal alert instead of throwing an error
            // MySwal.fire({
            //     icon: 'error',
            //     title: 'Error',
            //     text: 'Error fetching degree documents. Please try again.',
            // });
        }
    };


    const fetchVcs = async (userName) => {
        try {
          // Fetch the document CIDs for the given user
          const fetchDocsResponse = await fetch(`https://bankdb.myearth.id/api/vcnew/company/${userName}`);
          const vcData = await fetchDocsResponse.json();
          console.log('API Response:', vcData.response);
      
          if (!Array.isArray(vcData.response)) {
            throw new Error('Invalid response data format');
          }
      
          // Separate the VCs into arrays based on requestType
          const idVcs = [];
          const projectVcs = [];
          const trainingVCs = [];
          const professionalVcs = [];
      
          vcData.response.forEach((vcObj) => {
            const vc = vcObj.vc; // Access the 'vc' field within the object
            if (vc.credentialSubject[0].requestType === 'BirthCertificate') {
              idVcs.push(vc);
            } else if (vc.credentialSubject[0].requestType === 'DeathCertificate') {
              projectVcs.push(vc);
            } else if (vc.credentialSubject[0].requestType === 'MarriageCertificate') {
              trainingVCs.push(vc);
            }
            // Add more conditions for additional categories if needed
          });
      
          // Set the arrays of VCs to the state variables
          setIdVcs(idVcs);
          setOnBoardingVcs(projectVcs);
          setTrainingVCs(trainingVCs);
          setProfessionalVCs(professionalVcs);

          const uniqueRequestTypes = Array.from(
            new Set([
              ...idVcs.map((vc) => vc.credentialSubject[0].requestType),
              ...projectVcs.map((vc) => vc.credentialSubject[0].requestType),
              ...trainingVCs.map((vc) => vc.credentialSubject[0].requestType),
              ...professionalVcs.map((vc) => vc.credentialSubject[0].requestType),
            ])
          );

          setUniqueReqType(uniqueRequestTypes)
      
          console.log('API Response ID:', idVcs);
          console.log('API Response project:', projectVcs);
          console.log('API Response training:', trainingVCs);
          console.log('API Response prof:', professionalVcs);
      
          // Set the array of VCs to the state variable
          setVcs(vcData.response);
      
        } catch (error) {
          console.error('Error fetching documents:', error);
          // Handle the error, e.g., display an error message
        }
      };



    const issueCertificatesForAllUsers = async () => {
        try {
            setLoading(true); // Set loading state to true

            // Show loading Swal alert
            const loadingSwal = MySwal.fire({
                title: 'Issuing Certificates',
                html: 'Please wait while certificates are being generated...',
                allowOutsideClick: false,
                showConfirmButton: false,
                onBeforeOpen: () => {
                    MySwal.showLoading();
                },
            });

            // Loop through each user and issue certificates
            for (const user of users) {
                //setSelectedUser({ userName: user }); // Set the selected user
                await handleGenerateCertificateAll(user); // Issue degree certificate
                await handleGenerateIDCertificateAll(user); // Issue ID certificate
            }

            // Close the loading Swal alert
            loadingSwal.close();

            setLoading(false); // Set loading state to false

            // Show success Swal alert after successful completion
            MySwal.fire({
                icon: 'success',
                title: 'Certificates Issued Successfully',
                text: 'Certificates have been issued successfully for all users.',
            });
        } catch (error) {
            console.error('Error issuing certificates:', error);
            setLoading(false); // Set loading state to false

            // Show error Swal alert if there is an error during certificate issuance
            MySwal.fire({
                icon: 'error',
                title: 'Certificate Issuance Failed',
                text: 'Error issuing certificates. Please try again.',
            });
        }
    };




    const handleUserClick = async (user) => {
        try {
            setLoading(true); // Set loading state to true

            const pdfs = await fetchDocs(user);
            const vcs = await fetchVcs(user);
            const userData = {
                id: user.id,
                userName: user,
                userDID: user.userDID,
                pdfs,
                vcs
            };

            setSelectedUser(userData);
            setLoading(false); // Set loading state to false
            setSelectedUserDetails(user);
            setSelectedDocument(null);
            setSelectedMetadata(null);
            setSelectedId(null)

        } catch (error) {
            console.error('Error fetching user documents:', error);
        }
    };

    const handleDocumentView = (document, metaData, id, docHash) => {
        setSelectedDocument(document);
        setSelectedMetadata(metaData)
        setSelectedId(id)
        setSelectedDocHash(docHash)
        setShowUserList(false);
      };
      
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

const kycFlowComplete = () => {
    Swal.fire({
        title: 'Loading...',
        allowOutsideClick: false, // Prevent user interaction during the delay
        showConfirmButton: false, // Hide the OK button
        willOpen: () => {
          setTimeout(() => {
            // After the delay, show the success alert
            Swal.fire({
              title:  'Success',
              text: 'Operation completed successfully!',
              icon: 'success',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'OK',
            });
          }, 2000);
        },
      });
}
    

    const handleGenerateCertificate = async () => {
        if (selectedUser) {
            try {
                const loadingSwal = MySwal.fire({
                    title: 'Generating Certificate',
                    html: 'Please wait while the certificate is being generated...',
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    onBeforeOpen: () => {
                        MySwal.showLoading();
                    },
                });

                const documentMetadata = [...onBoardingvcs];
                //await createUserSignature(documentMetadata);
                await createVC(documentMetadata);

                loadingSwal.close();

                console.log('Certificate generated successfully!');
                MySwal.fire({
                    icon: 'success',
                    title: 'Certificate Generated',
                    text: 'Certificate generated successfully!',
                    showCancelButton: true,
                    confirmButtonText: 'Ok',
                })
            } catch (error) {
                console.error('Error generating certificate:', error);
                MySwal.fire({
                    icon: 'error',
                    title: 'Certificate Generation Failed',
                    text: 'Error generating certificate. Please try again.',
                });
            }
        }
    };

    // const handleGenerateCertificate = async () => {
    //     if (selectedUser) {
    //         try {
    //             const loadingSwal = MySwal.fire({
    //                 title: 'Generating Certificate',
    //                 html: 'Please wait while the certificate is being generated...',
    //                 allowOutsideClick: false,
    //                 showConfirmButton: false,
    //                 onBeforeOpen: () => {
    //                     MySwal.showLoading();
    //                 },
    //             });

    //             const documentMetadata = [...onBoardingvcs];
    //             //await createUserSignature(documentMetadata);
    //             await createVC(documentMetadata);

    //             loadingSwal.close();

    //             console.log('Certificate generated successfully!');
    //             MySwal.fire({
    //                 icon: 'success',
    //                 title: 'Certificate Generated',
    //                 text: 'Certificate generated successfully!',
    //                 showCancelButton: true,
    //                 confirmButtonText: 'View Certificate',
    //             }).then((result) => {
    //                 if (result.isConfirmed) {
    //                     const certificateUrl = `/certificate/degree/${selectedUser.userName}`; // Use the selected user's userName here
    //                     const newTabWindow = window.open(certificateUrl, '_blank');
    //                     newTabWindow.focus();
    //                 }
    //             });
    //         } catch (error) {
    //             console.error('Error generating certificate:', error);
    //             MySwal.fire({
    //                 icon: 'error',
    //                 title: 'Certificate Generation Failed',
    //                 text: 'Error generating certificate. Please try again.',
    //             });
    //         }
    //     }
    // };

    const handleGenerateIDCertificate = async () => {
        if (selectedUser) {
            try {
                const loadingSwal = MySwal.fire({
                    title: 'Generating Certificate',
                    html: 'Please wait while the certificate is being generated...',
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    onBeforeOpen: () => {
                        MySwal.showLoading();
                    },
                });

                const documentMetadata = [...idvcs];
                //await createUserSignature(documentMetadata);
                await createIDVC(documentMetadata);

                loadingSwal.close();

                console.log('Certificate generated successfully!');
                MySwal.fire({
                    icon: 'success',
                    title: 'Certificate Generated',
                    text: 'Certificate generated successfully!',
                    showCancelButton: true,
                    confirmButtonText: 'View Certificate',
                }).then((result) => {
                    if (result.isConfirmed) {
                        const certificateUrl = `/certificate/student-ID/${selectedUser.userName}`; // Use the selected user's userName here
                        const newTabWindow = window.open(certificateUrl, '_blank');
                        newTabWindow.focus();
                    }
                });
            } catch (error) {
                console.error('Error generating certificate:', error);
                MySwal.fire({
                    icon: 'error',
                    title: 'Certificate Generation Failed',
                    text: 'Error generating certificate. Please try again.',
                });
            }
        }
    };


    const handleGenerateCertificateAll = async (user) => {

        try {
            const loadingSwal = MySwal.fire({
                title: 'Generating Certificate',
                html: 'Please wait while the certificate is being generated...',
                allowOutsideClick: false,
                showConfirmButton: false,
                onBeforeOpen: () => {
                    MySwal.showLoading();
                },
            });

            const documentMetadata = [...onBoardingvcs];
            //await createUserSignature(documentMetadata);
            await createVCAll(documentMetadata, user);

            loadingSwal.close();

            console.log('Certificate generated successfully!');
            MySwal.fire({
                icon: 'success',
                title: 'Certificate Generated',
                text: 'Certificate generated successfully!',
                showCancelButton: true,
                confirmButtonText: 'View Certificate',
            }).then((result) => {
                if (result.isConfirmed) {
                    const certificateUrl = `/certificate/degree/${user}`; // Use the selected user's userName here
                    const newTabWindow = window.open(certificateUrl, '_blank');
                    newTabWindow.focus();
                }
            });
        } catch (error) {
            console.error('Error generating certificate:', error);
            MySwal.fire({
                icon: 'error',
                title: 'Certificate Generation Failed',
                text: 'Error generating certificate. Please try again.',
            });
        }

    };

    const handleGenerateIDCertificateAll = async (user) => {

        try {
            const loadingSwal = MySwal.fire({
                title: 'Generating Certificate',
                html: 'Please wait while the certificate is being generated...',
                allowOutsideClick: false,
                showConfirmButton: false,
                onBeforeOpen: () => {
                    MySwal.showLoading();
                },
            });

            const documentMetadata = [...idvcs];
            //await createUserSignature(documentMetadata);
            await createIDVCAll(documentMetadata, user);

            loadingSwal.close();

            console.log('Certificate generated successfully!');
            MySwal.fire({
                icon: 'success',
                title: 'Certificate Generated',
                text: 'Certificate generated successfully!',
                showCancelButton: true,
                confirmButtonText: 'View Certificate',
            }).then((result) => {
                if (result.isConfirmed) {
                    const certificateUrl = `/certificate/student-ID/${user}`; // Use the selected user's userName here
                    const newTabWindow = window.open(certificateUrl, '_blank');
                    newTabWindow.focus();
                }
            });
            setSelectedUser(null);
        } catch (error) {
            console.error('Error generating certificate:', error);
            MySwal.fire({
                icon: 'error',
                title: 'Certificate Generation Failed',
                text: 'Error generating certificate. Please try again.',
            });
        }

    };


    //SSI APis

    // SSI Apis
    // const createUserSignature = async (documentMetadata) => {
    //     try {
    //         const userDid = await createUserDid();
    //         const payload = {
    //             payload: {
    //                 credentialSubject: {
    //                     id: userDid,
    //                     vcsArray: documentMetadata,
    //                 },
    //             },
    //         };

    //         const config = {
    //             method: 'post',
    //             url: `${ssiBaseUrl}/api/user/sign?issuerDID=${issuerDID}`,
    //             headers: {
    //                 'X-API-KEY': authorizationKey,
    //                 privateKey,
    //                 'Content-Type': 'application/json',
    //             },
    //             data: JSON.stringify(payload),
    //         };

    //         const response = await axios.request(config);
    //         console.log(response.data)
    //         return response.data.Signature;
    //     } catch (error) {
    //         console.log(error);
    //         throw error;
    //     }
    // };


    const createIDVC = async (documentMetadata, user) => {
        try {
            //const signature = await createUserSignature(documentMetadata);
            const data = {
                schemaName: 'StudentVCSchema:1',
                isEncrypted: false,
                dependantVerifiableCredential: [],
                credentialSubject: {
                    vcsArray: documentMetadata
                },
            };

            const config = {
                method: 'post',
                url: `${ssiBaseUrl}/api/issuer/verifiableCredential?isCryptograph=false&downloadCryptograph=false`,
                headers: {
                    'X-API-KEY': authorizationKey,
                    did: userDID,
                    publicKey,
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(data),
            };

            const response = await axios.request(config);
            console.log('VC response', response.data.data.verifiableCredential)
            const verifiableCredential = response.data.data.verifiableCredential;
            // Store the VC using the storeVC API
            await storeIDVC(user, userDID, verifiableCredential);
            const certName = 'Student ID'
            handleAddUserCertList(selectedUser.userName, certName)
            //return verifiableCredential

        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    const createIDVCAll = async (documentMetadata, user) => {
        try {
            //const signature = await createUserSignature(documentMetadata);
            const data = {
                schemaName: 'StudentVCSchema:1',
                isEncrypted: false,
                dependantVerifiableCredential: [],
                credentialSubject: {
                    vcsArray: documentMetadata
                },
            };

            const config = {
                method: 'post',
                url: `${ssiBaseUrl}/api/issuer/verifiableCredential?isCryptograph=false&downloadCryptograph=false`,
                headers: {
                    'X-API-KEY': authorizationKey,
                    did: userDID,
                    publicKey,
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(data),
            };

            const response = await axios.request(config);
            console.log('VC response', response.data.data.verifiableCredential)
            const verifiableCredential = response.data.data.verifiableCredential;
            // Store the VC using the storeVC API
            await storeIDVCAll(user, userDID, verifiableCredential);

            //return verifiableCredential

        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    const createVC = async (documentMetadata, user) => {
        console.log('DocumentMetaData', documentMetadata)
        try {
            //const signature = await createUserSignature(documentMetadata);
            const data = {
                schemaName: 'StudentVCSchema:1',
                isEncrypted: false,
                dependantVerifiableCredential: [],
                credentialSubject: {
                    vcsArray: documentMetadata
                },
            };

            const config = {
                method: 'post',
                url: `${ssiBaseUrl}/api/issuer/verifiableCredential?isCryptograph=false&downloadCryptograph=false`,
                headers: {
                    'X-API-KEY': authorizationKey,
                    did: userDID,
                    publicKey,
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(data),
            };

            const response = await axios.request(config);
            console.log('VC response', response.data.data.verifiableCredential)
            const verifiableCredential = response.data.data.verifiableCredential;
            // Store the VC using the storeVC API
            await storeVC(user, userDID, verifiableCredential);
            console.log('Request Type', verifiableCredential.credentialSubject.requestType)
            
            //return verifiableCredential

        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    const createVCAll = async (documentMetadata, user) => {
        try {
            //const signature = await createUserSignature(documentMetadata);
            const data = {
                schemaName: 'StudentVCSchema:1',
                isEncrypted: false,
                dependantVerifiableCredential: [],
                credentialSubject: {
                    vcsArray: documentMetadata
                },
            };

            const config = {
                method: 'post',
                url: `${ssiBaseUrl}/api/issuer/verifiableCredential?isCryptograph=false&downloadCryptograph=false`,
                headers: {
                    'X-API-KEY': authorizationKey,
                    did: userDID,
                    publicKey,
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(data),
            };

            const response = await axios.request(config);
            console.log('VC response', response.data.data.verifiableCredential)
            const verifiableCredential = response.data.data.verifiableCredential;
            // Store the VC using the storeVC API
            await storeVCAll(user, userDID, verifiableCredential);

            //return verifiableCredential

        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    const createUserDid = async () => {
        // Implement the logic to create a user DID (if required)
        // For now, returning a placeholder value
        return 'did:earthid:testnet:9WzSyPSBkbUQVktjGxmvKAzbTNJCs1PFqo2fQ6BnuHiE;earthid:testnet:fid=0.0.15057499';
    };



    //SSI Apis
    const storeIDVC = async (user, userDID, verifiableCredential) => {
        try {
            const prefix = 'IDCertificate'; // Replace with your desired prefix
            const userNamee = `${prefix}${selectedUser.userName}`;
            console.log('UserName', userNamee)
            const payload = {
                company: userNamee,
                type: 'CertificateVc',
                vc: verifiableCredential,
            };

            const config = {
                method: 'post',
                //url: 'https://bankdb.myearth.id/api/vc/storeVC',
                url: 'https://bankdb.myearth.id/api/vcnew/store',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(payload),
            };

            const response = await axios.request(config);
            console.log('Store VC response:', response.data);
            return response.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    const storeIDVCAll = async (user, userDID, verifiableCredential) => {
        try {
            const prefix = 'IDCertificate'; // Replace with your desired prefix
            const userNamee = `${prefix}${user}`;
            console.log('UserName', userNamee)
            const payload = {
                company: userNamee,
                type: 'CertificateVc',
                vc: verifiableCredential,
            };

            const config = {
                method: 'post',
                //url: 'https://bankdb.myearth.id/api/vc/storeVC',
                url: 'https://bankdb.myearth.id/api/vcnew/store',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(payload),
            };

            const response = await axios.request(config);
            console.log('Store VC response:', response.data);
            return response.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    const storeVC = async (user, userDID, verifiableCredential) => {
        try {
            const prefix = 'KYCCertificate'; // Replace with your desired prefix
            const userNamee = `${prefix}${selectedUser.userName}`;
            console.log('UserName', userNamee)
            const payload = {
                company: userNamee,
                type: 'CertificateVc',
                vc: verifiableCredential,
            };

            const config = {
                method: 'post',
                //url: 'https://bankdb.myearth.id/api/vc/storeVC',
                url: 'https://bankdb.myearth.id/api/vcnew/store',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(payload),
            };

            const response = await axios.request(config);
            console.log('Store VC response:', response.data);
            return response.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    const storeVCAll = async (user, userDID, verifiableCredential) => {
        try {
            const prefix = 'OnBoardingCertificate'; // Replace with your desired prefix
            const userNamee = `${prefix}${user}`;
            console.log('UserName', userNamee)
            const payload = {
                company: userNamee,
                type: 'CertificateVc',
                vc: verifiableCredential,
            };

            const config = {
                method: 'post',
                //url: 'https://bankdb.myearth.id/api/vc/storeVC',
                url: 'https://bankdb.myearth.id/api/vcnew/store',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(payload),
            };

            const response = await axios.request(config);
            console.log('Store VC response:', response.data);
            return response.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    };



    const verifyVC = async (credential) => {
        try {

            //const signature = await createUserSignature();

            const data = { "credentials": credential }
            console.log(data)
            const config = {
                method: 'post',
                url: ssiBaseUrl + "/api/verifier/verify?zpkValidation=false",
                headers: {
                    'X-API-KEY': authorizationKey,
                    'publicKey': publicKey,
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data)
            };

            const response = await axios.request(config);
            console.log(JSON.stringify(response.data));

            return response.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }



    const handleVerifyDoc = async (credential) => {
        // Display 'Verifying...' Swal alert
        Swal.fire({
            title: 'Verifying...',
            text: 'Please wait while the document is being verified.',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });

        try {
            // Perform the verification process
            const response = await verifyVC(credential); // Replace 'verifyVC()' with the actual verification function

            // Hide the 'Verifying...' Swal alert
            Swal.close();

            // Show success Swal alert if verification is successful
            if (response.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Verification Successful',
                    text: 'The document has been verified successfully.',
                });
            } else {
                // Show error Swal alert if verification fails
                Swal.fire({
                    icon: 'error',
                    title: 'Verification Failed',
                    text: 'The document verification failed.',
                });
            }

            // Return the verification result
            return response;
        } catch (error) {
            // Hide the 'Verifying...' Swal alert
            Swal.close();

            // Show error Swal alert if an error occurs during verification
            Swal.fire({
                icon: 'error',
                title: 'Verification Error',
                text: 'An error occurred during the document verification.',
            });

            // Throw the error to be caught by the calling function
            throw error;
        }
    };


    const handleAddUserCertList = (userName, certName) => {
       
        //const certName = 'Audit Certificate'
            // Prepare the data for the POST request
            const data = { userName: userName,
                 certName: certName };
        
            // Make the POST request to add the user certification
            fetch('https://bankdb.myearth.id/api/certificate/addUserCert', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
              .then(response => response.json())
              .then(data => {
                console.log('User certification added:', data);
                // Clear the input fields after successful submission
               
              })
              .catch(error => console.error('Error adding user certification:', error));
          };

    const toggleView = () => {
        setShowTable((prevShowTable) => !prevShowTable);
    };

    //SSI Apis End

    const handleButtonClick = (buttonLabel) => {
        setSelectedButton(buttonLabel);
    };

    const handleBackToUserList = () => {
        setSelectedUser(false);
        setSelectedDocument(null);
        setSelectedMetadata(null);
        setSelectedId(null)
      };


    // const renderUserList = () => {
    //     return users.map((user) => (
    //         <div
    //             key={user}
    //             onClick={() => handleUserClick(user)}
    //             className={`user-item ${selectedUser && selectedUser.userName === user ? 'selected' : ''}`}
    //         >
    //             {user}
    //         </div>
    //     ));
    // };

console.log('Selected', selectedMetadata)
const getMetaDataForSelectedDocument = (selectedDocument) => {
    const documentData = pdfs.response.find(pdf => pdf.doc === selectedDocument);
    console.log(documentData)
    return documentData ? documentData.metaData : null;
  };
      
    const handleLogout = () => {
        window.location.href = "/";
    }
    // const DocumentViewerContainer = () => {
    //     const [document, setDocument] = useState(null);
      
    //     return document ? (
    //       <DocumentViewer document={document} onClose={() => setDocument(null)} />
    //     ) : null;
    //   };

      
//     const renderPDFViewer = () => {
      
//         if (!selectedUser) {
//             return <p className="no-user"></p>;
//         }

//         if (loading) {
//             return <p className="loading">Documents Loading...</p> // Render loading message
//         }

//         return (
//             <div className="pdf-viewer">
//                 <div className="pdf-header">
//                     <h3>Student: {selectedUser.userName}</h3>
//                     <div className="action-buttons">
//                         <button className="issue-button" onClick={handleGenerateCertificate}>
//                             Issue Degree Certificate
//                         </button>
//                         <button className="issue-button" onClick={handleGenerateIDCertificate}>
//                             Issue ID Certificate
//                         </button>
//                     </div>
//                 </div>
//                 <div className="pdf-container">
//                     {pdfs.map((pdf, index) => (
//                         <div key={`document_${index}`} className="document-container">
//                             <h3 className='fileName'>{pdf}</h3>
//                             {/* <h4>Document {index + 1}</h4> */}
//                             <div className="metadata-container">
//                                 {/* <p className="metadata-label">{pdf}</p> */}
//                                 {/* <p className="metadata-value">Metadata value</p> */}
//                             </div>
//                             <div className='docView'>
//                                 <h3>Preview:</h3>
//                                 <Document file={`https://bankdb.myearth.id/${pdf}`} onLoadSuccess={onDocumentLoadSuccess}>
//                                     {Array.from(new Array(numPages), (el, pageIndex) => (
//                                         <Page
//                                             key={`page_${index + 1}_${pageIndex + 1}`}
//                                             pageNumber={pageIndex + 1}
//                                             width={800}
//                                         />
//                                     ))}
//                                 </Document>
//                             </div>
//                         </div>
//                     ))}
//                     {/* Render verifiable credentials */}
//                     {/* {vcs.map((vc, index) => (
//   <div key={`vc_${index}`} className="vc-container">
//     <div className="row">
//       <h3 className="dgtProof">{vc.version == 'StudentIdVC:1' ? 'Student ID' : (vc.version == 'StudentDemoSchema:1' ? 'Student Document' : vc.version)} (VC):</h3>
//       {/* Toggle Button */}
//                     {/* <button className="changeViewbtn" onClick={toggleView}>
//         Change View
//       </button> */}
//                     {/* <button className="verifyBtn" onClick={() => handleVerifyDoc(vc)}>
//         Verify
//       </button> */}
//                     {/* </div>
//     {showTable ? ( */}
//                     {/* <pre>{JSON.stringify(vc, null, 2)}</pre>
//     ) : (
//       <pre> */}
//                     {/* <JsonToTable json={vc} />
//     </pre>
//     )} */}
//                     {/* <hr />
//   </div>
// ))} */}
//                 </div>
//             </div>
//         );
//     };



    return (
        <>

            <div className="admin-container">
                <div className="row">

                    <div className="sidebar col-md-2">
                        <div className='stuProf'>
                            <div className="photo">
                                <img src={studentPhoto} alt="Student Photo" />
                            </div>
                            <div className='stuDentDiv'>
                                <div className='stuUserNameHead'>
                                    Admin Name
                                </div>
                                <div className='stuUserName'>
                                    SuperAdmin
                                </div>
                            </div>

                        </div>
                        <div className='smallHr'>
                            <hr></hr>
                        </div>

                        <SidebarButton
                            label="Home"
                            icon={faHome}
                            onClick={() => handleButtonClick('Home')}
                            isSelected={selectedButton === 'Home'}
                        />

                        <SidebarButton
                            label="New Customers List"
                            icon={faList}
                            onClick={() => {
                                fetchUserList();
                                handleButtonClick('Student List');
                            }}
                            isSelected={selectedButton === 'Student List'}
                        />

                        {/* <SidebarButton
                            label="Issued Certificates"
                            icon={faCalendar}
                            onClick={() => handleButtonClick('Issued Certificate')}
                            isSelected={selectedButton === 'Issued Certificate'}
                        /> */}
                        <div className='smallHr'>
                            <hr></hr>
                        </div>

                        <SidebarButton
                            label="Help"
                            icon={faQuestionCircle}
                            onClick={() => {
                                handleButtonClick('Help');
                            }}
                            isSelected={selectedButton === 'Help'}
                        />

                        <SidebarButton
                            label="Logout Account"
                            icon={faRightFromBracket}
                            onClick={() => {
                                handleLogout()
                                handleButtonClick('Logout Account')
                            }}
                            isSelected={selectedButton === 'Logout Account'}
                            customClassName='redText'
                        />
                        {/* <button className='sidebarbtn' ><FontAwesomeIcon className='bellIcon' icon={faBell} /> Home</button>
                      
                        
                        <button className='sidebarbtn' onClick={fetchUserList}><FontAwesomeIcon className='bellIcon' icon={faBell} /> Student List</button>
                        
                        
                        <button className='sidebarbtn' ><FontAwesomeIcon className='bellIcon' icon={faBell} /> Issued Certificate</button>
                        */}

                    </div>
                    <div className="content col-md-10">
                        <div className='row miniNav'>
                            <div className='companyy'>
                                EarthID
                            </div>
                            <FontAwesomeIcon className='bellIcon' icon={faBell} />
                        </div>

                        {selectedButton === 'Home' && <div className='adminBack'>
                                <img src={adminBack}></img>
                            </div>}


{selectedButton === 'Student List' && <div>
{users.length > 0 ? (
                            <>
                                
                               
                                {selectedUser ? (
                                    <>
                                     <div className="pdf-viewer">
                                        <div className='backBtnDiv'>
                                        <button className='backBtn' onClick={handleBackToUserList}><FontAwesomeIcon className='backIcon' icon={faArrowLeft} /> Back</button>
                                        </div>
                                     
                                        <div className='studentName'><span>Name:</span> {selectedUser.userName}</div>
                                        
                

                                <div className='listTag'>Document List</div>
                <DocumentTable pdfs={pdfs} onDocumentView={handleDocumentView} />
                <div className="action-buttons">
                        <button className="issue-button" onClick={kycFlowComplete}>
                            Mark KYC Completed
                        </button>
                        {/* <button className="issue-button" onClick={handleGenerateIDCertificate}>
                            Issue ID Certificate
                        </button> */}
                    </div>
                {/* <div className="pdf-header">
                    
                    <div className="action-buttons">
                        <button className="issue-button" onClick={() => handleGenerateCertificate('EmploymentCertificate')}>
                            Issue Employement Certificate
                        </button>
                        <button className="issue-button" onClick={() => handleGenerateCertificate('ProjectCompletionCertificate')}>
                            Issue Project Completion Certificate
                        </button>
                        <button className="issue-button" onClick={() => handleGenerateCertificate('TrainingCertificate')}>
                            Issue Training Certificate
                        </button>
                        <button className="issue-button" onClick={() => handleGenerateCertificate('ProfessionalMembershipCertificate')}>
                            Issue Professional Certificate
                        </button>
                    </div>
                </div> */}
            </div>
                                    </>
                                    
            ) : (
                <>
                <div className='listTag'>List</div>
                <div className="user-tablee">
                                    <table className="user-table">
                                        <thead>
                                            <tr>
                                                <th>Sr No.</th>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>View</th>
                                                {/* <th>Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users.map((user, index) => {
                                                const userID = `202300${index + 1}`;
                                                return (
                                                    <tr key={user}>
                                                        <td>{index + 1}</td>
                                                        <td>{userID}</td>
                                                        <td>
                                                            <span >
                                                                {user}
                                                            </span>
                                                        </td>
                                                        <td>
                <FontAwesomeIcon onClick={() => handleUserClick(user)} className="eyeIcon" icon={faEye} />
              </td>

                                                        {/* <td>
            <button className="review-btn" onClick={() => handleUserClick(user)}>Display Documents</button>
          </td> */}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className='row'>
                                {/* <div className="issue-all-btn">
                                    <button onClick={issueCertificatesForAllUsers}>Issue Certificates to All Users</button>
                                </div> */}
                                {/* <div className="issue-all-btn">
                                    <button>Add Employees</button>
                                </div> */}
                                </div>
                                
                </>
            )}
                {selectedDocument && selectedMetadata && (
            //   <DocumentViewer
            //     document={`https://bankdb.myearth.id/${selectedDocument}`}
            //     onClose={() => setSelectedDocument(null)}
            //   />
            <div className="document-popup">
          <div className="document-popup-content">
            <DocumentViewer
              document={`https://bankdb.myearth.id/${selectedDocument}`}
              metaData={selectedMetadata}
              id={selectedId}
              docHash={selectedDocHash}
              onClose={() => setSelectedDocument(null)}
            />
          </div>
        </div>
            )}              
                               
                            </>

                        ) : (
                            <>
                <div className='listTag'>List</div>
                <div className="user-tablee">
                                    <table className="user-table">
                                        <thead>
                                            <tr>
                                                <th>Sr No.</th>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>View</th>
                                                {/* <th>Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users.map((user, index) => {
                                                const userID = `202300${index + 1}`;
                                                return (
                                                    <tr key={user}>
                                                        <td>{index + 1}</td>
                                                        <td>{userID}</td>
                                                        <td>
                                                            <span >
                                                                {user}
                                                            </span>
                                                        </td>
                                                        <td>
                <FontAwesomeIcon onClick={() => handleUserClick(user)} className="eyeIcon" icon={faEye} />
              </td>

                                                        {/* <td>
            <button className="review-btn" onClick={() => handleUserClick(user)}>Display Documents</button>
          </td> */}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className='row'>
                                {/* <div className="issue-all-btn">
                                    <button onClick={issueCertificatesForAllUsers}>Issue Certificates to All Users</button>
                                </div> */}
                                {/* <div className="issue-all-btn">
                                    <button>Add Employees</button>
                                </div> */}
                                </div>
                                
                </>
                        )}
    </div>}
    {selectedButton === 'Issued Certificate' && <>
<div className='listTag'>Issued Certificates</div>
<CertTable />
</>}

{selectedButton === 'Help' && <>
<div className='listTag'>Help Section</div>
</>}
                        
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Admin;