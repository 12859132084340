


import credit_card from "../../images/assets/features/credit-card.png"
import explore from "../../images/assets/features/explore.png"
import help from "../../images/assets/features/help.png"
import investment from "../../images/assets/features/investment.png"
import logo from "../../images/images/earthid-logo.png"
import "./baseui.css";
import { create } from 'exif-parser';
import { extract } from 'png-metadata';


import facebook from "../../images/assets/footer/facebook.png"
import twitter from "../../images/assets/footer/twitter.png"
import instagram from "../../images/assets/footer/instagram.png"
import linkedin from "../../images/assets/footer/linkedin.png"
import youtube from "../../images/assets/footer/youtube.png"
import mail from "../../images/assets/footer/mail.png"
import React, { useState, useEffect, Component } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import KycFlow from '../../kycQr';
import data from "../../api";
import openSocket from "socket.io-client";
import 'bootstrap/dist/css/bootstrap.min.css';
import crypto from 'crypto';


import { PDFDocument } from 'pdf-lib';
import VerifyDoc from "../../verifyDoc"


const fs = require('fs');
const MySwal = withReactContent(Swal);
// export const isTestnet = true;
// const socketURL =
//     isTestnet === true
//         ? "https://socket-stagev2.myearth.id"
//         : "https://socketv2.myearth.id";
// const socket = openSocket(socketURL, {
//     transports: ["websocket", "polling", "flashsocket"],
// });
// let socketId;
// socket.on("connect", () => {
//     socketId = socket.id;
//     //console.log(`I'm connected with the back-end`);
// });


const {
    listenForUserData
} = data;

const ssiBaseUrl = "https://ssi-test.myearth.id";
const authorizationKey = "01a41742-aa8e-4dd6-8c71-d577ac7d463c";

// const privateKey = "6mf4ZKu2ts0V4Dr0sS+YbXYveZOyDezzTJUqa5Ls29BHN4jBTY5we/WW0CgmuNGb3PBvdqqJPhDF2YCMnF4faw==";
// const publicKey = "RzeIwU2OcHv1ltAoJrjRm9zwb3aqiT4QxdmAjJxeH2s=";
// const issuerDID = "did:earthid:testnet:2utZGEuzWe8ds5Jaxd7KdMD7bwKMm8QiRKnS2t4Q8Dve;earthid:testnet:fid=0.0.15057499";
// const userDID = "did:earthid:testnet:9WzSyPSBkbUQVktjGxmvKAzbTNJCs1PFqo2fQ6BnuHiE;earthid:testnet:fid=0.0.15057499";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Baseui2 = () => {

  const [files, setFiles] = useState([]);
  const [docID, setDocID] = useState(null);
  const [vcID, setVcID] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const [responseDataID, setResponseDataID] = useState(null);
  const [responseDataTraining, setResponseDataTraining] = useState(null);
  const [responseDataMember, setResponseDataMember] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pdfFile, setPdfFile] = useState(null);
  const [numPdfPages, setNumPdfPages] = useState(null);
  const [numPages, setNumPages] = useState([]);
  const [pdfMetaData, setPdfMetaData] = useState([]);
  const [pdfs, setPdfs] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showMainContent, setShowMainContent] = useState(false); // State variable to track tab selection
  const [showAllDocs, setShowAllDocs] = useState(false);
  const [apiResponse, setApiResponse] = useState(null); // State variable to store API response
  const [vcResponse, setVcResponse] = useState(null); // State variable to store API response
  const [verifiableCredentials, setVerifiableCredentials] = useState([]);
  const [idVerifiableCredentials, setIdVerifiableCredentials] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [selectedButton, setSelectedButton] = useState("");
  const [loading, setLoading] = useState(false); // Added loading state
  const [ qRCodeUrl, setQRCodeUrl] = useState(null);
  const [ onqRCodeUrl, setonQRCodeUrl] = useState(null);
  const [ idqRCodeUrl, setIDQRCodeUrl] = useState(null);
  const [ trainingqRCodeUrl, setTrainingQRCodeUrl] = useState(null);
  const [ memberqRCodeUrl, setMemberQRCodeUrl] = useState(null);
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [showSendRequest, setShowSendRequest] = useState(false);
  const [clickedCertificate, setClickedCertificate] = useState('');

  const history = useHistory();




  useEffect(() => {
    getProfileData();
    if (profileData) {
        fetchDocs(profileData.userName);
      } else {
        console.log("Profile data not found");
      }
}, []);

const toggleView = () => {
    setShowTable((prevShowTable) => !prevShowTable);
};

const getProfileData = async () => {
    try {

        const profileData = sessionStorage.getItem('profileData');
        const bytes = profileData ? JSON.parse(profileData) : null;


        console.log('Bytes', bytes)

        setProfileData(bytes);

        setSelectedDocument(null);

        if (bytes && bytes.userName) {
            // Fetch documents using the userName from profileData if it exists
           await fetchDocs(bytes.userName);
            await fetchData(bytes.userName)
        }
    } catch (error) {
        console.log('Profile data not found');
    }
};
//const qrCodeUrl = ``  
//const qrCodeUrl = JSON.stringify(responseData[0]);
const [showAlert, setShowAlert] = useState(false);

const handleQRCodeClick = (certificate) => {
if(certificate === 'IDCertificate'){
    setQRCodeUrl(idqRCodeUrl);
}else if(certificate === 'ProjectCompletion'){
    setQRCodeUrl(qRCodeUrl);
}else if(certificate === 'Training'){
    setQRCodeUrl(trainingqRCodeUrl);
}else if(certificate === 'Membership'){
    setQRCodeUrl(memberqRCodeUrl);
}

setClickedCertificate(certificate);
setShowAlert(true);
};


const fetchData = async (userName) => {
    try {
        const prefix = 'ProjectCompletionCertificate'; // Replace with your desired prefix
        const idPrefix = 'EmploymentCertificate';
        const prefixTraining = 'TrainingCertificate'; // Replace with your desired prefix
        const prefixMember = 'IDCertificate';
        const idUserNamee = `${idPrefix}${userName}`;
        const userNamee = `${prefix}${userName}`;
        const trainingUserNamee = `${prefixTraining}${userName}`;
        const memeberuserNamee = `${prefixMember}${userName}`;
        console.log('Username', userNamee);
        const newQRCodeUrl = `https://bankdb.myearth.id/api/vcnew/company/${userNamee}/CertificateVc`;
        const newIDQRCodeUrl = `https://bankdb.myearth.id/api/vcnew/company/${idUserNamee}/CertificateVc`;
        const newQRCodeUrlTraining = `https://bankdb.myearth.id/api/vcnew/company/${trainingUserNamee}/CertificateVc`;
        const newQRCodeUrlMember = `https://bankdb.myearth.id/api/vcnew/company/${memeberuserNamee}/CertificateVc`;
        const responseOn = await fetch(`https://bankdb.myearth.id/api/vcnew/company/${userNamee}/CertificateVc`);
        const responseID = await fetch(`https://bankdb.myearth.id/api/vcnew/company/${idUserNamee}/CertificateVc`);
        const responseTraining = await fetch(`https://bankdb.myearth.id/api/vcnew/company/${trainingUserNamee}/CertificateVc`);
        const responseMember = await fetch(`https://bankdb.myearth.id/api/vcnew/company/${memeberuserNamee}/CertificateVc`);

        const cidJsonOn = await responseOn.json();
        console.log('CidsResponse', cidJsonOn);

        const cidJsonID = await responseID.json();
        console.log('CidsResponse', cidJsonID);

        const cidJsonTraining = await responseTraining.json();
        console.log('CidsResponse', cidJsonTraining);

        const cidJsonIDMember = await responseMember.json();
        console.log('CidsResponse', cidJsonIDMember);

        setIDQRCodeUrl(newIDQRCodeUrl)
        setonQRCodeUrl(newQRCodeUrl)
        setTrainingQRCodeUrl(newQRCodeUrlTraining)
        setMemberQRCodeUrl(newQRCodeUrlMember)

        if (cidJsonOn && cidJsonOn.response && cidJsonOn.response.length > 0) {
             setResponseData(cidJsonOn.response);
            // const response = await fetch('https://bankdb.myearth.id/api/vc/fetchVCMain', {
            //     method: 'POST',
            //     headers: {
            //         'Accept': 'application/vnd.ipld.car',
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify({
            //         cids: [
            //             cidJson.cids[cidJson.cids.length - 1]
            //         ],
            //     }),
            // });

            // if (response.ok) {
            //     const json = await response.json();
            //     console.log(json);
                
            // } else {
            //     console.log('Request failed with status code:', response.status);
            // }
        } else {
            console.log('Certificate not generated');
            //Swal.fire('Certificate Not Generated');
        }
        if (cidJsonID && cidJsonID.response && cidJsonID.response.length > 0) {
            setResponseDataID(cidJsonID.response);
           // const response = await fetch('https://bankdb.myearth.id/api/vc/fetchVCMain', {
           //     method: 'POST',
           //     headers: {
           //         'Accept': 'application/vnd.ipld.car',
           //         'Content-Type': 'application/json',
           //     },
           //     body: JSON.stringify({
           //         cids: [
           //             cidJson.cids[cidJson.cids.length - 1]
           //         ],
           //     }),
           // });

           // if (response.ok) {
           //     const json = await response.json();
           //     console.log(json);
               
           // } else {
           //     console.log('Request failed with status code:', response.status);
           // }
       } else {
           console.log('Certificate not generated');
           //Swal.fire('Certificate Not Generated');
       }
       if (cidJsonTraining && cidJsonTraining.response && cidJsonTraining.response.length > 0) {
        setResponseDataTraining(cidJsonTraining.response);
       // const response = await fetch('https://bankdb.myearth.id/api/vc/fetchVCMain', {
       //     method: 'POST',
       //     headers: {
       //         'Accept': 'application/vnd.ipld.car',
       //         'Content-Type': 'application/json',
       //     },
       //     body: JSON.stringify({
       //         cids: [
       //             cidJson.cids[cidJson.cids.length - 1]
       //         ],
       //     }),
       // });

       // if (response.ok) {
       //     const json = await response.json();
       //     console.log(json);
           
       // } else {
       //     console.log('Request failed with status code:', response.status);
       // }
   } else {
       console.log('Certificate not generated');
       //Swal.fire('Certificate Not Generated');
   }
   if (cidJsonIDMember && cidJsonIDMember.response && cidJsonIDMember.response.length > 0) {
    setResponseDataMember(cidJsonIDMember.response);
   // const response = await fetch('https://bankdb.myearth.id/api/vc/fetchVCMain', {
   //     method: 'POST',
   //     headers: {
   //         'Accept': 'application/vnd.ipld.car',
   //         'Content-Type': 'application/json',
   //     },
   //     body: JSON.stringify({
   //         cids: [
   //             cidJson.cids[cidJson.cids.length - 1]
   //         ],
   //     }),
   // });

   // if (response.ok) {
   //     const json = await response.json();
   //     console.log(json);
       
   // } else {
   //     console.log('Request failed with status code:', response.status);
   // }
} else {
   console.log('Certificate not generated');
   //Swal.fire('Certificate Not Generated');
}
    } catch (error) {
        console.log('Error:', error);
    }
    finally {
        setIsLoading(false);
    }
};




const closeAlert = () => {
setShowAlert(false);
};

const handleProfileButtonClick = () => {
    try {

        const profileData = sessionStorage.getItem('profileData');
        const bytes = profileData ? JSON.parse(profileData) : null;

        console.log('Bytes', bytes)
        setProfileData(bytes);


    } catch (error) {
        console.log('Profile data not found');
    }
};


const fetchDocs = async (userName) => {
    try {
        //setLoading(true); // Set loading state to true
        console.log(userName)
        // Fetch the document CIDs for the given user
        // const fetchDocsResponse = await fetch(`https://bankdb.myearth.id/api/doc/fetchDocCids/${userName}`);
        // const fetchDocsData = await fetchDocsResponse.json();
        // console.log(fetchDocsData);

        // if (!Array.isArray(fetchDocsData.cids)) {
        //     throw new Error('Invalid response data format');
        // }

        // // Prepare the request body with the CIDs
        // const requestBody = {
        //     cids: fetchDocsData.cids,
        // };
        // console.log(requestBody);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
          };
          
          const fetchDocsResponse =  await fetch(`https://bankdb.myearth.id/api/docnew/company/${userName}`)
          const fetchDocsData = await fetchDocsResponse.json();
          console.log('fetchdocsdata',fetchDocsData)

          if (!Array.isArray(fetchDocsData.response)) {
            throw new Error('Invalid response data format');
        }

        // Prepare the request body with the CIDs
        const requestBody = {
            response: fetchDocsData.response,
        };
        console.log(requestBody);
            // .then(response => response.text())
            // .then(result => console.log(result))
            // .catch(error => console.log('error', error));
        // Fetch the files based on the CIDs
        //const fetchFilesResponse = await fetch('https://bankdb.myearth.id/api/doc/retrieveFiles', {
            // const fetchFilesResponse = await fetch(`https://bankdb.myearth.id/api/docnew/company/${userName}`, {
            // method: 'GET',
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            //body: JSON.stringify(requestBody),
        //});
//console.log('FetchFileResponse', fetchDocsResponse)
        // if (!fetchFilesResponse.ok) {
        //     throw new Error('Failed to fetch files');
        // }

        // const fetchFilesText = await fetchFilesResponse.json();
        // console.log(fetchFilesText.fileNames)

        // Set the array of document URLs to the state variable
        setPdfs(requestBody);

        setLoading(false); // Stop loading
    } catch (error) {
        console.error('Error fetching documents:', error);
        setLoading(false); // Stop loading
        // Display Swal alert instead of throwing an error
        // MySwal.fire({
        //     icon: 'error',
        //     title: 'Error',
        //     text: 'Error fetching degree documents. Please try again.',
        // });
    }
};

const handleCreateID = async (requestType) => {
    try {
       if (profileData.length === 0) {
           // Display a message asking to add a document first
           Swal.fire({
               icon: 'warning',
               title: 'No Profile Info',
               text: 'Please register first.',
           });
           return;
       }
   
   
       // Show the loading Swal alert
       Swal.fire({
           title: 'Requesting',
           text: 'Please wait while we are sending your request.',
           allowOutsideClick: false,
           allowEscapeKey: false,
           allowEnterKey: false,
           showConfirmButton: false,
           onBeforeOpen: () => {
               Swal.showLoading();
           },
       });
       //const allMetadata = []; // Array to store all metadata for SSI APIs
   
       
   
               //await createUserIdSignature(profileData);
               await createIdVC(profileData, requestType)
               //allMetadata.push(documentMetadata); // Add metadata to the array
               Swal.fire({
                            icon: 'success',
                            title: 'Successful',
                            text: 'The request has been submitted successfully.',
                        });
       
    }catch (error){
       console.log('Id is not created')
    }
    }

// const handleCreateID = async () => {
//     try {
//       if (profileData.length === 0) {
//         // Display a message asking to add a document first
//         Swal.fire({
//           icon: 'warning',
//           title: 'No Profile Info',
//           text: 'Please register first.',
//         });
//         return;
//       }

//       // Start the createIdVC() process in parallel
// const createIdPromise = createIdVC(profileData);

  
//       // Display the loading Swal alert
//     //   const swalAlert = Swal.fire({
//     //     title: 'Requesting Student ID',
//     //     text: 'Please wait while the request for student ID is being sent.',
//     //     allowOutsideClick: false,
//     //     allowEscapeKey: false,
//     //     allowEnterKey: false,
//     //     showConfirmButton: false,
//     //     onOpen: async () => {

//     //          // Show loading animation
//     // Swal.showLoading();
//     //       // Simulate a delay (e.g., 2 seconds) before showing the next stage
//     //       await new Promise(resolve => setTimeout(resolve, 5000));
  
//     //       // Update the message for the next stage
//     //       Swal.update({
//     //         title: 'Almost there...',
//     //         text: 'Hold a little bit.',
           
//     //       });
//     //       Swal.showLoading();
//     //       await new Promise(resolve => setTimeout(resolve, 5000));
  
//     //       // Update the message for the next stage
//     //       Swal.update({
//     //         title: 'Sending Request',
//     //         text: 'Please wait while we are sending your request.',
           
//     //       });
  
//     //       // Show loading animation for the ID creation stage
//     // Swal.showLoading();
//           // Simulate another delay (e.g., 3 seconds) before creating the ID
//           //await new Promise(resolve => setTimeout(resolve, 3000));
  
//           // Perform the process to create student ID
//           await createIdPromise
//   console.log('Created ID VC')
//           // Hide the Swal alert
//           //Swal.close();
  
//           // Show success Swal alert if ID creation is successful
//     //       Swal.fire({
//     //         icon: 'success',
//     //         title: 'Successful',
//     //         text: 'The request for Student ID has been submitted successfully.',
//     //     });
//     //     },
//     //   });
//     } catch (error) {
//       console.log('ID is not created');
  
//       // Show error Swal alert if an error occurs during ID creation
//       Swal.fire({
//         icon: 'error',
//         title: 'ID Creation Error',
//         text: 'An error occurred during the student ID creation.',
//       });
//     }
//   };
   

// const handleCreateID = async () => {
//     try {
//         if (profileData.length === 0) {
//             // Display a message asking to add a document first
//             Swal.fire({
//                 icon: 'warning',
//                 title: 'No Profile Info',
//                 text: 'Please register first.',
//             });
//             return;
//         }


//         // Show the loading Swal alert
//         Swal.fire({
//             title: 'Requesting Student ID',
//             text: 'Please wait while the request for student ID is being sent.',
//             allowOutsideClick: false,
//             allowEscapeKey: false,
//             allowEnterKey: false,
//             showConfirmButton: false,
//             onBeforeOpen: () => {
//                 Swal.showLoading();
//             },
//         });
//         //const allMetadata = []; // Array to store all metadata for SSI APIs



//         //await createUserIdSignature(profileData);
//         await createIdVC(profileData)
//         //allMetadata.push(documentMetadata); // Add metadata to the array


//     } catch (error) {
//         console.log('Id is not created')
//     }
// }

function calculateSHA256(buffer) {
  const hash = crypto.createHash('sha256');
  console.log('Hash', hash)
  hash.update(buffer);
  return hash.digest('hex');
}

const getWalletDoc = () => {
    try {
        listenForUserData(async (err, res) => {
            let bytes = res.userDetails || res.newreq;
            //                 sessionStorage.setItem('profileData', JSON.stringify(bytes));
            // console.log('Got Session Data')
            //console.log("Upload bytes", bytes.base64);
            if(bytes.certificate){
              console.log("For ZKP Check, no document upload needed!")
            }else if(bytes.documentVc, bytes.selectedFields){
              const formattedFields = bytes.selectedFields.map(field => `${field.name}=${field.value}`);

console.log(formattedFields);
                if(bytes){
                    // Show loading swal
        const loadingSwal = Swal.fire({
          title: 'Loading',
          text: 'Verifying...',
          allowOutsideClick: false,
          showConfirmButton: false, 
          onBeforeOpen: () => {
              Swal.showLoading();
          }
      });

                  console.log("For SDD Check, no document upload needed!")
    //Add a function for verifySDD and use swal alert for the response
    const verifyRes = await verifySDD(bytes.documentVc, formattedFields, bytes.publicKey)
    console.log("VerifySDD", verifyRes)

    loadingSwal.close();
    if (verifyRes.success) {
      

      // Success alert
      Swal.fire({
          title: "Verification Successful",
          text: "The verification was successful.",
          icon: "success",
          willClose: () => {
            window.location.reload();
        }
      });
    } else {
     

      // Failed alert
      Swal.fire({
          title: "Verification Failed",
          text: "The verification failed. Please try again.",
          icon: "error",
          willClose: () => {
            window.location.reload();
        }
      });
    }
                }else{
                 

                    Swal.fire({
                        title: "Verification Failed",
                        text: "The verification failed. Please try again.",
                        icon: "error",
                        willClose: () => {
                          window.location.reload();
                      }
                    });
                }
            }else{
              const userName = bytes.userName
              const userDID = bytes.userDid
              const base64Array = bytes.base64;
          const pdfFileNameArray = bytes.docName;
  const docTypeArray = bytes.type;
              for (let j = 0; j < base64Array.length; j++) {
                const pdfFileName = pdfFileNameArray[j];
                let base64 = base64Array[j];
      const docType = docTypeArray[j];
                // Process the individual file
                const options = {
                  fileName: pdfFileName,
              };
              const prefix = "data:image/png;base64,";
  
  let modifiedBase64 = base64; // Use a different variable
  
  if (base64.startsWith(prefix)) {
    modifiedBase64 = base64.slice(prefix.length); // Remove the prefix
  }
  
  // Convert modifiedBase64 to Uint8Array
  const data = atob(modifiedBase64);
              //console.log('data', data)
              const uint8Array = new Uint8Array(data.length);
              for (let i = 0; i < data.length; ++i) {
                  uint8Array[i] = data.charCodeAt(i);
              }
  
              if(docType == 'pdf'){
  
  
                const blob = new Blob([uint8Array], { type: 'application/pdf' });
              const url = URL.createObjectURL(blob);
  
  
              console.log('pdfUrl', url)
              // Load the PDF document from the Uint8Array
              const pdfDoc = await PDFDocument.load(uint8Array, { ignoreEncryption: true });
              //console.log(pdfDoc)
  // Calculate the SHA-256 hash of the PDF content
  const sha256Hash = calculateSHA256(uint8Array);
  console.log('Mobile doc Hash', sha256Hash)
              console.log('URL converted to PDF successfully!');
              // Extract metadata from the PDF
              const metadata = {
                  producer: pdfDoc.getProducer(),
                  createdDate: pdfDoc.getCreationDate(),
                  modifiedDate: pdfDoc.getModificationDate(),
              };
              console.log('Metadata:', metadata);
  
              const uniqueId = generateUniqueId();
              // const formattedDateTime = formatDateTime(metadata.info.ModDate);
  
              const documentMetadata = {
                  metadata,
                  uniqueId,
                  // formattedDateTime,
              };
              // Save the PDF file path
              setPdfFile({
                  url: url,
                  metadata: documentMetadata,
                  fileName: pdfFileName,
              });
  
              const response = await fetch(url);
              const blobData = await response.blob();
              console.log(blobData, response)
              // Create a new File object
              const file = new File([blobData], pdfFileName, { type: blobData.type });
  
              console.log('File', file)
              Swal.fire({
                  title: 'Uploading Documents...',
                  text: 'Please wait while the documents are being uploaded.',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  allowEnterKey: false,
                  showConfirmButton: false,
                  onBeforeOpen: () => {
                      Swal.showLoading();
                  },
              });
              const metadataString = JSON.stringify(documentMetadata);
              const storeDocResponse =  await saveMetadataToDatabase( metadataString, file, sha256Hash);
              console.log('storeDoc', storeDocResponse)
              //saveMetadataToLocal(documentMetadata, file);
              //await createUserSignature(documentMetadata);
              Swal.showLoading();
              // Simulate a delay (e.g., 2 seconds) before showing the next stage
              await new Promise(resolve => setTimeout(resolve, 3000));
      
              // Update the message for the next stage
              Swal.update({
                title: 'Generating digital proofs...',
                text: 'Creating a digital trail for the KYC process.',
               
              });
              Swal.showLoading();
              // Simulate a delay (e.g., 2 seconds) before showing the next stage
              await new Promise(resolve => setTimeout(resolve, 3000));
      
              // Update the message for the next stage
              Swal.update({
                title: 'Almost there...',
                text: 'Hold a little bit.',
               
              });
              Swal.showLoading();
              const theVcResponse =  await createVC( documentMetadata)
             console.log('theVcResponse',theVcResponse)
              // Simulate a delay (e.g., 2 seconds) before showing the next stage
              //await new Promise(resolve => setTimeout(resolve, 3000));
            const storeVcResponse = await storeVC( theVcResponse);
              // Update the message for the next stage
              console.log('storeVC', storeVcResponse)
  
              
  
              setSelectedDocument(null);
              
             //await handleCreateID()
  
             handleProfileButtonClick()
                                     // Fetch documents using the userName from profileData if it exists
                                   await  fetchDocs(userName);
                                   if(storeDocResponse && storeVcResponse){
                                     await associateVcWithDocument(storeDocResponse, storeVcResponse)
                                 }else{
                                     console.log('No ID found')
                                 }
                                   Swal.fire({
                                     icon: 'success',
                                     title: 'Successful',
                                     text: 'KYC documents have been submitted successfully. You would recieve an acknowledgment in your wallet.',
                                     willClose: () => {
                                      window.location.reload();
                                  }
                                 });
  
         
            handleProfileButtonClick()
              
     
  
              }
  
              else if( docType == "jpg"){
                const blob = new Blob([uint8Array], { type: 'image/jpeg' });
                      const url = URL.createObjectURL(blob);
  
                      // Load the JPG image
                      const image = new Image();
                      image.src = url;
  
                      // You can access image width and height like this:
                      const width = image.width;
                      const height = image.height;
  
                      // Extract metadata from the JPG (you can use exif-parser or other libraries)
                      const metadata = extractJpgMetadata(uint8Array);
                      console.log('JPG Metadata:', metadata);
    // Calculate the SHA-256 hash of the PDF content
    const sha256Hash = calculateSHA256(uint8Array);
    console.log('Mobile doc Hash', sha256Hash)
                console.log('URL converted to PDF successfully!');
                // Extract metadata from the PDF
                
                
                const uniqueId = generateUniqueId();
                // const formattedDateTime = formatDateTime(metadata.info.ModDate);
    
                const documentMetadata = {
                    metadata,
                    uniqueId,
                    // formattedDateTime,
                };
                // Save the PDF file path
                setPdfFile({
                    url: url,
                    metadata: documentMetadata,
                    fileName: pdfFileName,
                });
    
                const response = await fetch(url);
                const blobData = await response.blob();
                console.log(blobData, response)
                // Create a new File object
                const file = new File([blobData], pdfFileName, { type: blobData.type });
    
                console.log('File', file)
                Swal.fire({
                    title: 'Uploading Documents...',
                    text: 'Please wait while the documents are being uploaded.',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: false,
                    onBeforeOpen: () => {
                        Swal.showLoading();
                    },
                });
                const metadataString = JSON.stringify(documentMetadata);
                const storeDocResponse =  await saveMetadataToDatabase( metadataString, file, sha256Hash);
                console.log('storeDoc', storeDocResponse)
                //saveMetadataToLocal(documentMetadata, file);
                //await createUserSignature(documentMetadata);
                Swal.showLoading();
                // Simulate a delay (e.g., 2 seconds) before showing the next stage
                await new Promise(resolve => setTimeout(resolve, 3000));
        
                // Update the message for the next stage
                Swal.update({
                  title: 'Generating digital proofs...',
                  text: 'Creating a digital trail for the KYC process.',
                 
                });
                Swal.showLoading();
                // Simulate a delay (e.g., 2 seconds) before showing the next stage
                await new Promise(resolve => setTimeout(resolve, 3000));
        
                // Update the message for the next stage
                Swal.update({
                  title: 'Almost there...',
                  text: 'Hold a little bit.',
                 
                });
                Swal.showLoading();
                const theVcResponse =  await createVC( documentMetadata)
               console.log('theVcResponse',theVcResponse)
                // Simulate a delay (e.g., 2 seconds) before showing the next stage
                //await new Promise(resolve => setTimeout(resolve, 3000));
              const storeVcResponse = await storeVC( theVcResponse);
                // Update the message for the next stage
                console.log('storeVC', storeVcResponse)
    
                
    
                setSelectedDocument(null);
                
               //await handleCreateID()
    
               handleProfileButtonClick()
                                       // Fetch documents using the userName from profileData if it exists
                                     await  fetchDocs(userName);
                                     if(storeDocResponse && storeVcResponse){
                                       await associateVcWithDocument(storeDocResponse, storeVcResponse)
                                   }else{
                                       console.log('No ID found')
                                   }
                                     Swal.fire({
                                       icon: 'success',
                                       title: 'Successful',
                                       text: 'KYC documents have been submitted successfully. You would recieve an acknowledgment in your wallet.',
                                       willClose: () => {
                                        window.location.reload();
                                    }
                                   });
    
           
              handleProfileButtonClick()
                
       
              }
  
              else if( docType == "png"){
                const blob = new Blob([uint8Array], { type: 'image/png' });
                      const url = URL.createObjectURL(blob);
  
                      // Load the JPG image
                      const image = new Image();
                      image.src = url;
  
                      // You can access image width and height like this:
                      const width = image.width;
                      const height = image.height;
  
                      // Extract metadata from the JPG (you can use exif-parser or other libraries)
                      const metadata = extractPngMetadata(uint8Array);
                      console.log('JPG Metadata:', metadata);
    // Calculate the SHA-256 hash of the PDF content
    const sha256Hash = calculateSHA256(uint8Array);
    console.log('Mobile doc Hash', sha256Hash)
                console.log('URL converted to PDF successfully!');
                // Extract metadata from the PDF
                
                
                const uniqueId = generateUniqueId();
                // const formattedDateTime = formatDateTime(metadata.info.ModDate);
    
                const documentMetadata = {
                    metadata,
                    uniqueId,
                    // formattedDateTime,
                };
                // Save the PDF file path
                setPdfFile({
                    url: url,
                    metadata: documentMetadata,
                    fileName: pdfFileName,
                });
    
                const response = await fetch(url);
                const blobData = await response.blob();
                console.log(blobData, response)
                // Create a new File object
                const file = new File([blobData], pdfFileName, { type: blobData.type });
    
                console.log('File', file)
                Swal.fire({
                    title: 'Uploading Documents...',
                    text: 'Please wait while the documents are being uploaded.',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: false,
                    onBeforeOpen: () => {
                        Swal.showLoading();
                    },
                });
                const metadataString = JSON.stringify(documentMetadata);
                const storeDocResponse =  await saveMetadataToDatabase( metadataString, file, sha256Hash);
                console.log('storeDoc', storeDocResponse)
                //saveMetadataToLocal(documentMetadata, file);
                //await createUserSignature(documentMetadata);
                Swal.showLoading();
                // Simulate a delay (e.g., 2 seconds) before showing the next stage
                await new Promise(resolve => setTimeout(resolve, 3000));
        
                // Update the message for the next stage
                Swal.update({
                  title: 'Generating digital proofs...',
                  text: 'Creating a digital trail for the KYC process.',
                 
                });
                Swal.showLoading();
                // Simulate a delay (e.g., 2 seconds) before showing the next stage
                await new Promise(resolve => setTimeout(resolve, 3000));
        
                // Update the message for the next stage
                Swal.update({
                  title: 'Almost there...',
                  text: 'Hold a little bit.',
                 
                });
                Swal.showLoading();
                const theVcResponse =  await createVC( documentMetadata)
               console.log('theVcResponse',theVcResponse)
                // Simulate a delay (e.g., 2 seconds) before showing the next stage
                //await new Promise(resolve => setTimeout(resolve, 3000));
              const storeVcResponse = await storeVC( theVcResponse);
                // Update the message for the next stage
                console.log('storeVC', storeVcResponse)
    
                
    
                setSelectedDocument(null);
                
               //await handleCreateID()
    
               handleProfileButtonClick()
                                       // Fetch documents using the userName from profileData if it exists
                                     await  fetchDocs(userName);
                                     if(storeDocResponse && storeVcResponse){
                                       await associateVcWithDocument(storeDocResponse, storeVcResponse)
                                   }else{
                                       console.log('No ID found')
                                   }
                                     Swal.fire({
                                       icon: 'success',
                                       title: 'Successful',
                                       text: 'KYC documents have been submitted successfully. You would recieve an acknowledgment in your wallet.',
                                       willClose: () => {
                                        window.location.reload();
                                    }
                                   });
    
           
              handleProfileButtonClick()
                
       
              }else {
                // Unsupported document type
                Swal.fire({
                    icon: 'error',
                    title: 'Unsupported Document Type',
                    text: 'The document type is not supported.',
                    willClose: () => {
                      window.location.reload();
                  }
                });
            }
              //console.log('uintArr', uint8Array)
              
              }
             
            }
             // Convert base64 to PDF
           // const pdfFileName = bytes.docName; // Provide a file name for the PDF
            
          // Show loading animation for the ID creation stage
    
        });
    } catch (error) {
        console.log('Document not found');
    }
};

// Function to extract metadata from a JPG file (using exif-parser)
const extractJpgMetadata = (jpgUint8Array) => {
  try {
    // const parser = create(jpgUint8Array);
    // console.log('Parser',parser)
    // const result = parser.parse();

    return {
      producer: 'JPG Producer', // Customize the producer field as needed
      createdDate: new Date().getTime(), // Replace with the actual created date
        modifiedDate: new Date().getTime(), // Replace with the actual modified date
    };
  } catch (error) {
    console.error('Error extracting metadata from JPEG:', error);
    return {};
  }
};

const extractPngMetadata = (pngUint8Array) => {
  try {
    const metadata = extract(pngUint8Array);
    
    return {
      producer: 'PNG Producer', // Customize the producer field as needed
      createdDate: new Date().getTime(), // Replace with the actual created date
        modifiedDate: new Date().getTime(), // Replace with the actual modified date
      // Extract other PNG metadata fields as needed
    };
  } catch (error) {
    console.error('Error extracting metadata from PNG:', error);
    return {};
  }
};

const onDocumentLoadSuccess = ({ numPdfPages }) => {
    setNumPdfPages(numPdfPages);
};


const handleKYCFlowComplete = () => {
    // Call the getWalletDoc function


    getWalletDoc();

};


const handleViewCertificate = () => {
    // Perform any necessary actions before redirecting

    // Redirect to the desired component
    window.open(`/certificate/project-completion/${profileData.userName}`, '_blank');
};

const handleViewIDCertificate = () => {
    // Perform any necessary actions before redirecting

    // Redirect to the desired component
    window.open(`/certificate/employment/${profileData.userName}`, '_blank');
};

const handleViewTrainingCertificate = () => {
    // Perform any necessary actions before redirecting

    // Redirect to the desired component
    window.open(`/certificate/training/${profileData.userName}`, '_blank');
};

const handleViewMemberCertificate = () => {
    // Perform any necessary actions before redirecting

    // Redirect to the desired component
    window.open(`/certificate/professional-membership/${profileData.userName}`, '_blank');
};

const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
};

const handleFileUpload = async () => {
    if (files.length === 0) {
        // Display a message asking to add a document first
        Swal.fire({
            icon: 'warning',
            title: 'Add Document',
            text: 'Please add a document first.',
        });
        return;
    }


    // Show the loading Swal alert
    Swal.fire({
        title: 'Uploading Documents...',
        text: 'Please wait while the documents are being uploaded.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
            Swal.showLoading();
        },
    });
    //const allMetadata = []; // Array to store all metadata for SSI APIs

    for (const file of files) {
        const reader = new FileReader();
        reader.onloadend = async () => {
            const arrayBuffer = reader.result;
            const pdf = await pdfjs.getDocument(arrayBuffer).promise;

            const numPages = pdf.numPages;
            setNumPages((prevNumPages) => [...prevNumPages, numPages]);

            const metadata = await pdf.getMetadata();
            const uniqueId = generateUniqueId();
            //const formattedDateTime = formatDateTime(metadata.info.ModDate);

            const documentMetadata = {
                ...metadata.info,
                uniqueId,
                //formattedDateTime,
            };

            const metadataString = JSON.stringify(documentMetadata);
        const storeDocResponse =  await saveMetadataToDatabase(metadataString, file);
console.log('storeDoc', storeDocResponse)
           await saveMetadataToLocal(metadataString, file);
            //await createUserSignature(documentMetadata);

            Swal.showLoading();
            // Simulate a delay (e.g., 2 seconds) before showing the next stage
            //await new Promise(resolve => setTimeout(resolve, 5000));
    
            // Update the message for the next stage
            Swal.update({
              title: 'Generating Verifiable Credential...',
              text: 'Creating a VC of document metadata.',
             
            });
            Swal.showLoading();
           const theVcResponse =  await createVC(documentMetadata)
           console.log('theVcResponse',theVcResponse)
            // Simulate a delay (e.g., 2 seconds) before showing the next stage
            //await new Promise(resolve => setTimeout(resolve, 3000));
          const storeVcResponse = await storeVC(profileData.userDid, theVcResponse);
            // Update the message for the next stage
            console.log('storeVC', storeVcResponse)
            Swal.update({
              title: 'Almost there...',
              text: 'Hold a little bit.',
             
            });
            Swal.showLoading();
            
            
            setSelectedDocument(null);
           //await handleCreateID()
handleProfileButtonClick()
                // Fetch documents using the userName from profileData if it exists
              await  fetchDocs(profileData.userName);
              if(storeDocResponse && storeVcResponse){
                await associateVcWithDocument(storeDocResponse, storeVcResponse)
            }else{
                console.log('No ID found')
            }
              Swal.fire({
                icon: 'success',
                title: 'Successful',
                text: 'On-boarding documents and its verifiable credentials have been submitted successfully.',
            });
            //allMetadata.push(documentMetadata); // Add metadata to the array
        };
        reader.readAsArrayBuffer(file);
    }

    // Set showMainContent to true after file upload

    // Display success message after file upload
    //   Swal.fire({
    //     icon: 'success',
    //     title: 'Successful',
    //     text: 'The document has been submitted successfully.',
    //   });

    // Call the SSI APIs using allMetadata

};


const generateUniqueId = () => {
    return Math.random().toString(36).substr(2, 9);
};
// SSI Apis
// const createUserSignature = async (documentMetadata) => {
//     try {
//         const userDid = await createUserDid();
//         const payload = {
//             payload: {
//                 credentialSubject: {
//                     id: userDid,
//                     pdfMetaData: documentMetadata,
//                 },
//             },
//         };

//         const config = {
//             method: 'post',
//             url: `${ssiBaseUrl}/api/user/sign?issuerDID=${issuerDID}`,
//             headers: {
//                 'X-API-KEY': authorizationKey,
//                 privateKey,
//                 'Content-Type': 'application/json',
//             },
//             data: JSON.stringify(payload),
//         };

//         const response = await axios.request(config);
//         console.log(response.data)
//         return response.data.Signature;
//     } catch (error) {
//         console.log(error);
//         throw error;
//     }
// };

const createVC = async (documentMetadata) => {
    try {
        //const signature = await createUserSignature(documentMetadata);
        const data = {
            schemaName: 'BankDemoSchema:1',
            isEncrypted: false,
            dependantVerifiableCredential: [],
            credentialSubject: {
                pdfMetaData: documentMetadata
            },
        };

        const config = {
            method: 'post',
            url: `${ssiBaseUrl}/api/issuer/verifiableCredential?isCryptograph=false&downloadCryptograph=false`,
            headers: {
                'X-API-KEY': authorizationKey,
                did: profileData.userDid,
                publicKey: profileData.publicKey,
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(data),
        };

        const response = await axios.request(config);
        console.log('VC response', response.data.data.verifiableCredential)
        const verifiableCredential = response.data.data.verifiableCredential;


        // Store the VC using the storeVC API
        
        setVcResponse(verifiableCredential)
        // Swal.fire({
        //     icon: 'success',
        //     title: 'Successful',
        //     text: 'On-boarding documents and its verifiable credentials have been submitted successfully.',
        // });

        // Emit the VC data to the socket connection
        //socket.emit('vcData', verifiableCredential);
        setVerifiableCredentials((prevCredentials) => [...prevCredentials, verifiableCredential]);

        setShowAllDocs(true)
        return verifiableCredential;

    } catch (error) {
        console.log(error);
        throw error;
    }
};

// const createUserIdSignature = async (profileData) => {
//     try {
//         const userDid = await createUserDid();
//         const payload = {
//             payload: {
//                 credentialSubject: {
//                     id: userDid,
//                     userName: profileData.userName,
//                     earthId: profileData.earthId,
//                     userMobileNo: profileData.userMobileNo,
//                     userEmail: profileData.userEmail
//                 },
//             },
//         };

//         const config = {
//             method: 'post',
//             url: `${ssiBaseUrl}/api/user/sign?issuerDID=${issuerDID}`,
//             headers: {
//                 'X-API-KEY': authorizationKey,
//                 privateKey,
//                 'Content-Type': 'application/json',
//             },
//             data: JSON.stringify(payload),
//         };

//         const response = await axios.request(config);
//         console.log(response.data)
//         return response.data.Signature;
//     } catch (error) {
//         console.log(error);
//         throw error;
//     }
// };



const createIdVC = async (profileData, requestType) => {
    try {
        //const signature = await createUserIdSignature(profileData);
        const data = {
            schemaName: 'ProfessionalCredVC:1',
            isEncrypted: false,
            dependantVerifiableCredential: [],
            credentialSubject: {
                requestType: requestType,
                userName: profileData.userName,
                earthId: profileData.earthId,
                userMobileNo: profileData.userMobileNo,
                userEmail: profileData.userEmail
            },
        };

        const config = {
            method: 'post',
            url: `${ssiBaseUrl}/api/issuer/verifiableCredential?isCryptograph=false&downloadCryptograph=false`,
            headers: {
                'X-API-KEY': authorizationKey,
                did: profileData.userDid,
                publicKey: profileData.publicKey,
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(data),
        };

        const response = await axios.request(config);
        console.log('VC response', response.data.data.verifiableCredential)
        const verifiableCredential = response.data.data.verifiableCredential;


        // Store the VC using the storeVC API
        await storeVC(profileData.userDid, verifiableCredential);
        setVcResponse(verifiableCredential)
        // Swal.fire({
        //     icon: 'success',
        //     title: 'Successful',
        //     text: 'The request for Student ID has been submitted successfully.',
        // });

        // Emit the VC data to the socket connection
        //socket.emit('vcData', verifiableCredential);
        setIdVerifiableCredentials((prevCredentials) => [...prevCredentials, verifiableCredential]);

        setShowAllDocs(true)
        return response.data;

    } catch (error) {
        console.log(error);
        throw error;
    }
};

const createUserDid = async () => {
    // Implement the logic to create a user DID (if required)
    // For now, returning a placeholder value
    return 'did:earthid:testnet:9WzSyPSBkbUQVktjGxmvKAzbTNJCs1PFqo2fQ6BnuHiE;earthid:testnet:fid=0.0.15057499';
};



//SSI Apis

const saveMetadataToLocal = async (metadata, file) => {
    const storedMetadata = localStorage.getItem('pdfMetaData');
    let allMetadata = storedMetadata ? JSON.parse(storedMetadata) : [];
    allMetadata.push(metadata);
    localStorage.setItem('pdfMetaData', JSON.stringify(allMetadata));
    console.log(allMetadata);

    setPdfMetaData((prevPdfMetaData) => [...prevPdfMetaData, metadata]);
};

const saveMetadataToDatabase = async ( metadata, file, sha256Hash) => {
    const formData = new FormData();

    formData.append('doc', file);
    formData.append(
        'company',
        profileData.userName
    );

    formData.append(
        'userDID',
        profileData.userDid
    );
    formData.append(
        'metaData',
        metadata
    )
    formData.append(
      'docHash',
      sha256Hash
  )

    try {
        //const response = await axios.post('https://bankdb.myearth.id/api/doc/storeApi', formData);
        const response = await axios.post('https://bankdb.myearth.id/api/docnew/store', formData);
        console.log(response.data); // Handle the response from the API
        const newDocId = response.data.documentId
        console.log("newDocID", newDocId)
// setDocID(newDocId)
// console.log("DocID", docID)
        // Store the response in a state variable
        setApiResponse(response.data);
        return newDocId
    } catch (error) {
        console.error(error); // Handle the error
    }
};

const storeVC = async ( verifiableCredential) => {
    try {
        const payload = {
            company: profileData.userName,
            type: 'DocumentVc',
            vc: verifiableCredential,
        };
console.log('Payload', payload)
        const config = {
            method: 'post',
            //url: 'https://bankdb.myearth.id/api/vc/storeVC',
            url: 'https://bankdb.myearth.id/api/vcnew/store',
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(payload),
        };

        const response = await axios.request(config);
        console.log('Store VC response:', response.data);
        const newVcId = response.data.vcId
        console.log("newVcID", newVcId)
        // setVcID(newVcId)
        // console.log("VCID", vcID)
        return newVcId;
    } catch (error) {
        console.log(error);
        //throw error;
    }
};


const verifyVC = async (credential) => {
    try {

        //const signature = await createUserSignature();

        const data = { "credentials": credential }
        console.log(data)
        const config = {
            method: 'post',
            url: ssiBaseUrl + "/api/verifier/verify?zpkValidation=false",
            headers: {
                'X-API-KEY': authorizationKey,
                'publicKey': profileData.userDid,
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
        };

        const response = await axios.request(config);
        console.log(JSON.stringify(response.data));

        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

const verifySDD = async (credential, verifyParams) => {
  try {

      //const signature = await createUserSignature();

      const data = { "credentials": credential, "verifyParams": verifyParams }
      console.log(data)
      const config = {
          method: 'post',
          url: ssiBaseUrl + "/api/verifier/verify?zpkValidation=false",
          headers: {
              'X-API-KEY': authorizationKey,
              'publicKey': profileData.userDid,
              'Content-Type': 'application/json'
          },
          data: JSON.stringify(data)
      };

      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));

      return response.data;
  } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Verification Failed",
        text: "The verification failed. Please try again.",
        icon: "error",
        willClose: () => {
          window.location.reload();
      }
    });
      throw error;
  }
}

// const handleQRCodeClick = async () => {
//     const qrCodeCanvas = await toPng(<QRCode value={qrCodeUrl} />); // Convert QRCode component to canvas image

//     Swal.fire({
//       title: 'QR Code',
//       html: `<div class="qrcode-container"><img src="${qrCodeCanvas}" alt="QR Code" /></div>`,
//       showCancelButton: false,
//       showConfirmButton: false,
//       allowOutsideClick: true,
//     });
//   };

const handleVerifyDoc = async (credential) => {
    // Display 'Verifying...' Swal alert
    Swal.fire({
        title: 'Verifying...',
        text: 'Please wait while the document is being verified.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
            Swal.showLoading();
        },
    });

    try {
        // Perform the verification process
        const response = await verifyVC(credential); // Replace 'verifyVC()' with the actual verification function

        // Hide the 'Verifying...' Swal alert
        Swal.close();

        // Show success Swal alert if verification is successful
        if (response.success) {
            Swal.fire({
                icon: 'success',
                title: 'Verification Successful',
                text: 'The document has been verified successfully.',
            });
        } else {
            // Show error Swal alert if verification fails
            Swal.fire({
                icon: 'error',
                title: 'Verification Failed',
                text: 'The document verification failed.',
            });
        }

        // Return the verification result
        return response;
    } catch (error) {
        // Hide the 'Verifying...' Swal alert
        Swal.close();

        // Show error Swal alert if an error occurs during verification
        Swal.fire({
            icon: 'error',
            title: 'Verification Error',
            text: 'An error occurred during the document verification.',
        });

        // Throw the error to be caught by the calling function
        throw error;
    }
};

// After the VC is stored successfully, call the following API to associate the VC with the uploaded document
const associateVcWithDocument = async (documentId, vcId) => {
try {
  const response = await axios.post(`https://bankdb.myearth.id/api/docnew/associate-vc/${documentId}`, {
    vcId: vcId,
  });
  console.log(response.data.message);
} catch (error) {
  console.error(error);
}
};

const handleDocumentView = (document) => {
    setSelectedDocument(document);
};

const handleLogout = () => {
    window.location.href = "/";
}


const handleSelectCertificate = (certificateName) => {
    setSelectedCertificate(certificateName);
    setShowSendRequest(true);
  };

  return (
    <>


            

             
                <KycFlow onComplete={handleKYCFlowComplete} />
                
                
         
          
            
               


   
    <div className="home-hero">
            <div className="hero__box">
              <h2 className="hero__box-feature">
                What we Do? Find more about EarthID
              </h2>
              <a
                href="https://www.youtube.com/channel/UCaRjLyN4I_0UrK3NL4zU1Vg/featured"
                target="blank"
                className="btn-know-us btn btn--white btn--animated"
              >
                Know us
              </a>
            </div>
          </div>

          <section className="features">
            <div className="feature-section">
              <div className="feature__row">
                <div className="feature-box">
                  <img
                    className="feature feature__1"
                    src={credit_card}
                    alt="Feature 1"
                  />
                    <h2 className="feature-box__text">Credit Cards</h2>
                </div>

                <div className="feature-box">
                  <img
                    className="feature feature__2"
                    src={investment}
                    alt="Feature 2"
                  />
                  
                    <h2 className="feature-box__text">
                      Lucrative Investments
                    </h2>
                  
                </div>

                <div className="feature-box">
                  <img
                    className="feature feature__3"
                    src={explore}
                    alt="Feature 3"
                  />
                  
                    <h2 className="feature-box__text">Best schemes & Plans</h2>
                
                </div>

                <div className="feature-box">
                  <img
                    className="feature feature__4"
                    src={help}
                    alt="Feature 4"
                  />
                  
                    <h2 className="feature-box__text">Seamless Support 24x7</h2>
                 
                </div>
              </div>
            </div>
          </section>

          <section className="accounts-section">
            <div className="account__property">
              <div className="account_property-div">
                <div className="account__property__box account__property__picture-box"></div>

                <div className="account__property__box account__property__text-box">
                  <h2 className="account__property__text__heading">
                    Get the best advice from top experts.
                  </h2>
                  <p className="account__property__text__information">
                    Here, at EarthID we have some of the greatest financial
                    experts in the market who are always willing to help you on
                    your financial path.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="aection-footer">
            <div className="footered">
              <div className="footer__text__box">
                <h2 className="footer__text">Connect with us</h2>
              </div>

              <div className="footer__icon__div">
                <div className="footer__icon__box">
                  <a
                    className="footer__icon "
                    href="https://twitter.com/earthid_ssi"
                    target="blank"
                  >
                    <img
                      className="footer__icon footer__icon__1"
                      src={twitter}
                      alt="Icon 1"
                    />
                  </a>
                </div>

                <div className="footer__icon__box">
                  <a
                    className="footer__icon "
                    href="https://www.facebook.com/myearth.id/"
                    target="blank"
                  >
                    <img
                      className="footer__icon__2"
                      src={facebook}
                      alt="Icon 2"
                    />
                  </a>
                </div>

                <div className="footer__icon__box">
                  <a
                    className="footer__icon "
                    href="https://www.linkedin.com/company/myearthid/"
                    target="blank"
                  >
                    <img
                      className="footer__icon_3"
                      src={linkedin}
                      alt="Icon 3"
                    />
                  </a>
                </div>

                <div className="footer__icon__box">
                  <a
                    className="footer__icon"
                    href="https://www.youtube.com/channel/UCaRjLyN4I_0UrK3NL4zU1Vg/featured"
                    target="blank"
                  >
                    <img
                      className="footer__icon_4"
                      src={youtube}
                      alt="Icon 4"
                    />
                  </a>
                </div>

                <div className="footer__icon__box">
                  <a
                    className="footer__icon"
                    href="https://www.instagram.com/myearthid/"
                    target="blank"
                  >
                    <img
                      className=" footer__icon__5"
                      src={instagram}
                      alt="Icon 5"
                    />
                  </a>
                </div>

                <div className="footer__icon__box">
                  <a className="footer__icon" href="" target="blank">
                    <img
                      className=" footer__icon__6"
                      src={mail}
                      alt="Icon 6"
                    />
                  </a>
                </div>
              </div>
            </div>
          </section>

    </>
  )
}

export default Baseui2
