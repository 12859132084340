import React, { useState } from 'react';
import axios from 'axios';
import './adhaarOTP.css';

const AadhaarOtp = () => {
    const [aadhaarNumber, setAadhaarNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [response, setResponse] = useState(null);

    const appId = '6654764fbd5ae100285d9509';  // Replace with your actual app-id
    const apiKey = 'GQSBQX2-2Z847D6-KZV30H6-HR1W56F';  // Replace with your actual api-key

    const handleRequestOtp = async () => {
        try {
            const headers = {
                'app-id': appId,
                'api-key': apiKey
            };
            const data = { aadhaarNumber };

            const result = await axios.post('https://idv.myearth.id/request-otp', data, { headers });
            console.log('Req Otp:', result.data);
            setResponse(result.data);
        } catch (error) {
            console.error('Error requesting OTP:', error);
        }
    };

    const handleVerifyOtp = async () => {
        try {
            console.log('RequestOtp response data', response);
            const headers = {
                'app-id': appId,
                'api-key': apiKey
            };

            const requestId = response.request_id;
            const taskId = response.task_id;

            const data = { requestId, otp, taskId };
            console.log('VerifyApi call data', data);
            const result = await axios.post('https://idv.myearth.id/verify-otp', data, { headers });
            setResponse(result.data);
        } catch (error) {
            console.error('Error verifying OTP:', error);
        }
    };

    return (
        <div className="container">
            <div className="wrapper">
                <div className="left-pane">
                    <h3>Aadhaar Verification</h3>
                    

                    <div className="input-container">
                    <div className='instext'>
                    <p >Fill in the below details to run verification</p>
                    </div>
                        <input
                            type="text"
                            placeholder="Enter Aadhaar Number"
                            value={aadhaarNumber}
                            onChange={(e) => setAadhaarNumber(e.target.value)}
                        />
                        <button onClick={handleRequestOtp}>Send OTP</button>
                    </div>
                    {response && response.request_id && (
                        <div className="input-container">
                          
                            <input
                                type="text"
                                placeholder="Enter OTP"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                            />
                            <button onClick={handleVerifyOtp}>Run Verification</button>
                        </div>
                    )}
                </div>
                <div className='linee'></div>
                <div className="right-pane">
                    <h3>Verification Data</h3>
                    {response && <div className="response-container"><pre>{JSON.stringify(response, null, 2)}</pre></div>}
                </div>
            </div>
        </div>
    );
};

export default AadhaarOtp;
