import React, { Component } from "react";
import openSocket from "socket.io-client";
import CryptoJS from "react-native-crypto-js";
import crypto, { createHmac } from "crypto";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import GenerateCred from "./components/GenerateCred/generateCred";
import VerifyCreditnals from "./components/VerifyCreditnals/generateCred";
import data from "./api";
import { store } from "react-notifications-component";
import "./App.css";
import Swal from "sweetalert2";
import { SHA256 } from "crypto-js";


import logo from "./images/images/earthid-logo.png"

import credit_card from "./images/assets/features/credit-card.png"
import explore from "./images/assets/features/explore.png"
import help from "./images/assets/features/help.png"
import investment from "./images/assets/features/investment.png"

import facebook from "./images/assets/footer/facebook.png"
import twitter from "./images/assets/footer/twitter.png"
import instagram from "./images/assets/footer/instagram.png"
import linkedin from "./images/assets/footer/linkedin.png"
import youtube from "./images/assets/footer/youtube.png"
import mail from "./images/assets/footer/mail.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";




const {
  listenForUserData,
  listenForServiceProviderResponse,
  getApiKey,
  issuerDidgenerateqr,
  createIssuerDid,
  generateQrCodeNest,
  getUserCreditnals,
  generateqr,
  verifier_listenForUserData,
  verifier_listenForServiceProviderResponse,
  verifier_generateqr,
  verifyCreditnals,
  registerVendor,
} = data;

let showNotification = true;

class VerifyDoc extends Component {
  state = {
    showSpinner: false,
    showBtn: true,
    requestNo: "",
    qrcode: false,
    response: false,
    email: "",
    mno: "",
    profileDrop: false,
    btnText: "Login with Earth Id",
    showText: true,
    showModal: true,
    modalHeading: false,
    createDid: "",
    creditnals: "",
  };

  loader = () => {
    this.setState({ showBtn: false, modalHeading: true });
    this.setState({ qrcode: true, showSpinner: false });
    if (!this.state.showModal) {
      this.setState({
        showModal: true,
      });
    }
    if (this.state.loggedIn) {
      localStorage.removeItem("email");
      localStorage.removeItem("mobile_no");
      localStorage.removeItem("user_name");
      localStorage.removeItem("dob");
      localStorage.removeItem("e_verified");
      localStorage.removeItem("trust_score");
      localStorage.removeItem("earth_id");
      localStorage.removeItem("m_verified");
      this.setState({
        loggedIn: false,
        btnText: "Login with Earth Id",
        qrcode: true,
        response: false,
        showModal: false,
      });
      setTimeout(() => {
        document.getElementById("exampleModal").click();
      }, 0);
      this.redirectToHome();
    }
  };

  componentDidMount = async () => {
    if (localStorage.email != null) {
        this.setState({
            loggedIn: true,
            btnText: "LogOut",
        });
    }
    if (!this.state.apiKey) {
        this.state.apiKey = getApiKey();
    }
};

updateNotificationState = () => {
    setTimeout(() => {
        showNotification = true;
    }, 100);
    this.redirectToGenerateCred();
};

verifyNotificationState = () => {
    setTimeout(() => {
        showNotification = true;
    }, 100);
    this.redirectToVerifyCreditnals();
};

userDenied = () => {
    this.setState({
        response: false,
        showModal: false,
    });
    setTimeout(() => {
        document.getElementById("exampleModal").click();
        if (showNotification === true) {
            showNotification = false;
            store.addNotification({
                title: "Error!",
                message: `User Didn't Authorize!`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true,
                },
            });
        }
    }, 0);
};

listenForSocket = () => {
    listenForServiceProviderResponse((err, res) => {
      // console.log("nikhil", res);
        this.setState({
            qrcode: false,
            response: false,
            showSpinner: true,
            showText: false,
        });
    });

  listenForUserData(async (err, res) => {
        let bytes = res.userDetails || res.newreq;
        console.log("bytes", bytes);
        console.log("testing-----",res.userDetails);
        console.log("testing-------",res.newreq );
        console.log("nikhil", res);
        this.setState({
            showQr: false,
            showSpinner: false,
        });
        let creditnals;
        let sessionKey;
        if (bytes === "denied" || bytes.permission === "denied") {
            this.userDenied();
        } else if (
            bytes.permission === "granted" ||
            bytes === "issuerLogin" ||
            bytes === "credentialsgenerated" ||
            bytes === "verifylogin" ||
            bytes === "success"
        ) {
            this.setState({
                response: true,
                showSpinner: false,
                loggedIn: true,
                showModal: false,
                btnText: "LogOut",
                bytes: "credentialsgenerated",
            });
            setTimeout(() => {
                document.getElementById("exampleModal").click();
                let popMessage;
                if (showNotification === true) {
                    if (this.state.bytes === "credentialsgenerated") {
                        popMessage = "Credentials Generated";
                    } else {
                        popMessage = `Login Successful`;
                    }
                    showNotification = false;
                    store.addNotification({
                        title: "Success!",
                        message: popMessage,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true,
                        },
                    });
                    if (bytes.permission === "granted") {
                        this.updateNotificationState();
                    } else if (bytes === "verifylogin") {
                        this.verifyNotificationState();
                    }
                }
            }, 0);
        } else if (bytes.userDetails.credentialType != null) {
            console.log("bytes", bytes);
            sessionKey = bytes.sessionKey;
            await getUserCreditnals(bytes.userDetails)
                .then((data) => {
                    this.userCreditnalsSuccess(bytes);

                    creditnals = data;
                })
                .catch((err) => {
                    alert(err);
                });
        } else {
            this.setState({
                response: false,
                showModal: false,
            });
            setTimeout(() => {
                document.getElementById("exampleModal").click();
                if (showNotification === true) {
                    showNotification = false;
                    store.addNotification({
                        title: "Error!",
                        message: `User Didn't Authorize!`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true,
                        },
                    });
                }
            }, 0);
        }
    });
};

userCreditnalsSuccess = (data) => {
    if (data.userDetails.credentialType != null) {
        this.setState({
            response: true,
            showSpinner: false,
            loggedIn: true,
            showModal: false,
            btnText: "LogOut",
        });
        setTimeout(() => {
            document.getElementById("exampleModal").click();
            let popMessage;
            if (showNotification === true) {
                popMessage = "Credentials Generated";
                showNotification = false;
                store.addNotification({
                    title: "Success!",
                    message: popMessage,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true,
                    },
                });
            } else {
                this.setState({
                    response: false,
                    showModal: false,
                });
                setTimeout(() => {
                    document.getElementById("exampleModal").click();
                    if (showNotification === true) {
                        showNotification = false;
                        store.addNotification({
                            title: "Error!",
                            message: `User Didn't Authorize!`,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 3000,
                                onScreen: true,
                            },
                        });
                    }
                }, 0);
            }
        }, 0);
    }
};

generateQrCodeData = async () => {
    // issuerDidgenerateqr("login", "loginDetailsEncryption")
    // .then(async (data) => {
    // this.listenForSocket();
    // this.setState({
    // showQr: true,
    // qrCodeData: data,
    // apiKey: this.state.apikey,
    // qrcode: false,
    // });
    // })
    // .catch((err) => {
    // alert(err);
    // });
    registerVendor()
        .then((d) => {
            const timestamp = Date.now();
            const hash = crypto
                .createHmac("sha256", d.secretKey)
                .update(JSON.stringify(d.apiKey + "Rejolut@1008" + timestamp))
                .digest("hex");
            generateQrCodeNest(hash, d.apiKey, "Rejolut@1008", timestamp, "login")
                .then((data) => {
                    this.listenForSocket();
                    this.setState({
                        showQr: true,
                        qrCodeData: data,
                        apiKey: this.state.apikey,
                        qrcode: false,
                    });
                })
                .catch((err) => {
                    alert(err);
                });
        })
        .catch((err) => {
            alert(err);
        });
};

generateCredQrCodeData = () => {
    registerVendor()
        .then((d) => {
            const timestamp = Date.now();
            const hash = crypto
                .createHmac("sha256", d.secretKey)
                .update(JSON.stringify(d.apiKey + "Rejolut@1008" + timestamp))
                .digest("hex");
            generateQrCodeNest(
                hash,
                d.apiKey,
                "Rejolut@1008",
                timestamp,
                "generateCredentials"
            )
                .then((data) => {
                    this.listenForSocket();
                    this.setState({
                        showQr: true,
                        qrCodeData: data,
                        apiKey: this.state.apikey,
                        qrcode: false,
                    });
                    console.log("test");
                })
                .catch((err) => {
                    alert(err);
                });
        })
        .catch((err) => {
            alert(err);
        });
};


/********* KYC function ************/  
QrCodeDataKYC = () => {
  registerVendor()
      .then((d) => {
          const timestamp = Date.now();
          const hash = crypto
              .createHmac("sha256", d.secretKey)
              .update(JSON.stringify(d.apiKey + "Rejolut@1008" + timestamp))
              .digest("hex");
          generateQrCodeNest(
              hash,
              d.apiKey,
              "Rejolut@1008",
              timestamp,
              "document"
          )
              .then((data) => {
                  this.listenForSocket();
                  this.setState({
                      showQr: true,
                      qrCodeData: data,
                      apiKey: this.state.apikey,
                      qrcode: false,
                  });
                  console.log("test");
              })
              .catch((err) => {
                  alert(err);
              });
      })
      .catch((err) => {
          alert(err);
      });
};




qrcode = () => {
    let { qrCodeData } = this.state;
    return (
        <div className="qr-container">
            <img src={qrCodeData} alt="new" />
        </div>
    );
};

redirectToHome() {
    window.location.href = "/";
}
redirectToGenerateCred() {
    window.location.href = "/generateCred";
}

redirectToVerifyCreditnals() {
    window.location.href = "/verifyCredentials";
}

//VERIFY CREDENTIALS

verifyQrCodeData = () => {
    // verifier_generateqr("shareCredentials")
    // .then((data) => {
    // // console.log("Error found")
    // this.verifier_listenForSocket();
    // this.setState({
    // showQr: true,
    // qrCodeData: data,
    // apiKey: this.state.apikey,
    // qrcode: false,
    // });
    // })
    // .catch((err) => {
    // alert(err.message);
    // });
    registerVendor()
        .then((d) => {
            const timestamp = Date.now();
            const hash = crypto
                .createHmac("sha256", d.secretKey)
                .update(JSON.stringify(d.apiKey + "Rejolut@1008" + timestamp))
                .digest("hex");
            generateQrCodeNest(
                hash,
                d.apiKey,
                "Rejolut@1008",
                timestamp,
                "shareCredentials",""
                
            )
                .then((data) => {
                    this.verifier_listenForSocket();
                    this.setState({
                        showQr: true,
                        qrCodeData: data,
                        apiKey: this.state.apikey,
                        qrcode: false,
                    });
                    console.log('QR Code Data')
                })
                .catch((err) => {
                    alert(err);
                    
                });
        })

        .catch((err) => {
            alert(err);
        });
};

verifier_listenForSocket = () => {
    verifier_listenForServiceProviderResponse((err, res) => {
        this.setState({
            qrcode: false,
            response: false,
            showSpinner: true,
            showText: false,
            
        });
    });

verifier_listenForUserData(async (err, res) => {
        let bytes = res.userDetails || res.newreq;
        console.log(bytes);
        if (bytes.permission === "denied" || bytes === "denied") {
            this.userDenied();
        } else {
            Swal.fire({
              html: `<table id="table" border=2>
              <thead>
      <tr>
          <th>Key</th>
          <th>Value</th>
      </tr>
  </thead>
              
              <tbody >

                  <tr>
                  <td>User Email</td>
                  <td>${bytes.userEmail}</td>    
                  </tr>

                  <tr>
                  <td>User Name</td>
                  <td>${bytes.userName}</td>    
                  </tr>

                  <tr>
                  <td>Mobile Number</td>
                  <td>${bytes.userMobileNo}</td>    
                  </tr>

                  <td>EarthID</td>
                  <td>${bytes.earthId}</td>    
                  </tr>

                  <tr>
                  <td>Permission</td>
                  <td>${bytes.permission}</td>    
                  </tr>
                  
                  <tr>
                  <td>Pressed</td>
                  <td>${bytes.pressed}</td>    
                  </tr>

                  <tr >
                  <td>KYC Token </td>
                  <td id= "kyc">${bytes.kycToken}</td>    
                  </tr>


                  
      </tbody>
      </table>`,
                // title: 'Are you sure?',
                text: bytes.credential,
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Verify",
                
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        position: "center",
                        //icon: 'success',
                        title: "Verifying....",
                        showConfirmButton: false,
                    });
                    verifyCreditnals(
                        bytes.credential,
                        bytes.publicKey,
                        bytes.signature,
                        bytes.verifyParams
                    ).then((data) => {
                      if (data.success === true) {
                          //interchanged verfication msg 
                          Swal.fire("Verification Failed");                        
                        } else {
                          Swal.fire("Verification Successful");
                        }
                    });
                }
            });
            this.setState({
                showQr: false,
                showSpinner: false,
            });
            if (bytes != null) {
                this.setState({
                    response: true,
                    showSpinner: false,
                    loggedIn: true,
                    isVerified: true,
                    showModal: false,
                    btnText: "LogOut",
                });
                setTimeout(() => {
                    document.getElementById("exampleModal").click();
                    let popMessage;
                    if (showNotification === true) {
                        if (bytes === "credentialsgenerated") {
                            popMessage = "Credentials Generated";
                        } else {
                            popMessage = `Credentials Received Successfully`;
                        }
                        showNotification = false;
                        store.addNotification({
                            title: "Success!",
                            message: popMessage,
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 3000,
                                onScreen: true,
                            },
                        });
                        if (bytes == "login") {
                            this.updateNotificationState();
                        } else if (bytes === "verifylogin") {
                            this.verifyNotificationState();
                        }
                    }
                }, 0);
            } else {
                this.setState({
                    response: false,
                    showModal: false,
                });
                setTimeout(() => {
                    document.getElementById("exampleModal").click();
                    if (showNotification === true) {
                        showNotification = false;
                        store.addNotification({
                            title: "Error!",
                            message: `User Didn't Authorize!`,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 3000,
                                onScreen: true,
                            },
                        });
                    }
                }, 0);
            }
        }
    });
};




  render() {
    let showButton = this.state.showSpinner ? (
      <div className="spinner-div">
        <img className="spinner" src={require("./spinner.gif")} />
      </div>
    ) : null;
    return (
   
                  <>
                  
               

                 
                  <button
                    type="button"
                    className="nav-buttons"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={this.verifyQrCodeData}
                    style={{ marginRight: "2rem" }}
                  >
                    {" "}
                    Share Documents
                  </button>

                  
                  <button
                    type="button"
                    className="nav-buttons"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={this.redirectToHome}
                  >
                    Logout
                  </button>

              
         
         
         

          <div className="modal_container" id="modal-container">
            <div className="modal">
              <div className="qr-code">
                <img
                  src='https://api.qrserver.com/v1/create-qr-code/?data={"apikey":"UKURGTREFATPSYJ","reqNo":"BAZQUVRDTLF","sessionKey":"BAZQUVRDTLF","requestType":"login","encryptionkey":"loginDetailsEncryption" }'
                  alt="QR-Code"
                />
              </div>

              <button id="btn-close">Close me</button>
            </div>
          </div>
          {/* </header> */}

          <div className="hero-section">
          <div className="shoppingCart"></div>
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              {
                <div>
                  {this.state.showModal ? (
                    <div className="modal-content">
                              <div className="close-button-containerr">
                      <h4 style={{ color: "black" }}>
                          {/* {this.state.modalHeading && !this.state.showSpinner
                            ? "Use Earth ID app to scan the QR code"
                            : "Waiting for user authorization"} */}
                             Scan me using your wallet
                        </h4>
    <FontAwesomeIcon className="close-buttonn" data-dismiss="modal"  icon={faClose} />
 
</div>
                      <div className="modal-body">
                        <div className="pop-upbox">
                          {showButton}
                          {this.state.response
                            ? null
                            : this.state.qrcode
                              ? this.generateQrCodeData()
                              : null}
                          {this.state.showQr ? (
                            this.qrcode()
                          ) : this.state.showSpinner ? (
                            <img
                              className="spinner"
                              src={require("./spinner.gif")}
                            />
                          ) : null}
                        </div>
                      </div>
                      {/* <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary p-btn"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                      </div> */}
                    </div>
                  ) : null}
                </div>
              }
            </div>
          </div>
        </div>
      
          
      </>
    );
  }
}

export default VerifyDoc;