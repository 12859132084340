import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Document, Page } from 'react-pdf';

const DocumentViewer = ({ document, onClose }) => {

 
  const isImage = (document) => {
    // Check if the URL contains "image" as a substring
    if (document.includes('images')) {
      return true;
    }
  
    // Add more keywords or patterns to check for PDF or other formats
    // if (document.includes('pdf')) {
    //   return false; // Assume it's not an image
    // }
  
    // Default to assuming it's not an image
    return false;
  };

  const isImageFile =  isImage(document);
console.log('Is it an image?', isImageFile);

  return (
    <div className="document-popup">
      <div className="popup-content">
      <div className="close-button-container">
    <FontAwesomeIcon className="close-button" onClick={onClose} icon={faClose} />
 
</div>
      
        <h3>Preview:</h3>
          
        {isImageFile ? (
        
        <img src={document} alt="Document" />
     ) : (
       <Document file={document}>
       <Page pageNumber={1} width={500} height={400} />
     </Document>
     )}
      </div>
    </div>
  );
};

export default DocumentViewer;