import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import './admin.css';

const UserCertTable = () => {
  const [userCerts, setUserCerts] = useState([]);

  useEffect(() => {
    // Fetch the API data when the component mounts
    fetch('https://bankdb.myearth.id/api/certificate/getAllUserCerts')
      .then(response => response.json())
      .then(data => {
        // Group certificates by userName and create an array of objects with userName and unique certificate names
        const userCertGroups = data.reduce((groups, userCert) => {
          if (!groups[userCert.userName]) {
            groups[userCert.userName] = [];
          }
          if (!groups[userCert.userName].includes(userCert.certName)) {
            groups[userCert.userName].push(userCert.certName);
          }
          return groups;
        }, {});

        // Convert the grouped data to an array of objects
        const distinctUserCerts = Object.keys(userCertGroups).map(userName => ({
          userName,
          certNames: userCertGroups[userName]
        }));

        setUserCerts(distinctUserCerts);
      })
      .catch(error => console.error('Error fetching user certs:', error));
  }, []);

  const handleViewCertificate = (userName, certName) => {
    // Perform any necessary actions before redirecting

    if (certName === 'Birth Certificate') {
      window.open(`/certificate/birth/${userName}`, '_blank');
    } else if (certName === 'Death Certificate') {
      window.open(`/certificate/death/${userName}`, '_blank');
    }else if (certName === 'Marriage Certificate') {
      window.open(`/certificate/marriage/${userName}`, '_blank');
    }
    // Add more conditions for other certificate names if needed
  };

  return (
    <div className='user-tablee'>
      <table className='user-table'>
        <thead>
          <tr>
            <th>Sr No.</th>
            <th>Employee Name</th>
            <th>Issued Certificate</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody>
          {userCerts.map((userCert, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{userCert.userName}</td>
              <td>
                {userCert.certNames.map((certName, certIndex) => (
                  <div key={certIndex}>{certName}</div>
                ))}
              </td>
              <td>
                {userCert.certNames.map((certName, certIndex) => (
                  <div key={certIndex}>
                    <FontAwesomeIcon
                      onClick={() => handleViewCertificate(userCert.userName, certName)}
                      className="eyeIcon"
                      icon={faEye}
                    />
                  </div>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserCertTable;
