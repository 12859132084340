import React, { useState, useEffect, useRef } from 'react';
import './certificate.scss';
import logo from '../../img/EarthID Logo - Primary - BLACK.png'
import favicon from '../../img/EarthID FAVICON - PRIMARY.png'
import check from '../../img/check.png'
import ssiApi from '../../ssiApi.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useParams } from "react-router-dom";
import QRCode from 'qrcode.react';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, pdf } from '@react-pdf/renderer';
import CertificatePDF from './downloadCert';
import { saveAs } from 'file-saver'; // Import the file-saver library
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import CertificateIcon from '../../img/certIcon.png'
import BadgeIcon from '../../img/badgeIcon.png'
import BadgeImg from '../../img/badgeImg.jpeg'
import YellowBadge from '../../img/rosette.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocation, faPhone, faEnvelope, faEllipsisVertical, faIdBadge, faFilePdf, faImage, faShare, faQuestionCircle, faArrowDown, faCaretDown, faPen, faUser, faMedal, faArrowUpRightFromSquare, faCheckCircle, faBitcoinSign } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagramSquare, faLinkedin, faYoutubeSquare, faTwitterSquare, faWhatsapp, faFacebookSquare, faWhatsappSquare, faLinkedinIn, faTwitter, faEmber, faBitcoin } from '@fortawesome/free-brands-svg-icons'
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';

import { JsonToTable } from "react-json-to-table";
import axios from 'axios';
const moment = require('moment');


const ssiBaseUrl = "https://ssi-test.myearth.id";
const authorizationKey = "01a41742-aa8e-4dd6-8c71-d577ac7d463c";

const privateKey = "6mf4ZKu2ts0V4Dr0sS+YbXYveZOyDezzTJUqa5Ls29BHN4jBTY5we/WW0CgmuNGb3PBvdqqJPhDF2YCMnF4faw==";
const publicKey = "RzeIwU2OcHv1ltAoJrjRm9zwb3aqiT4QxdmAjJxeH2s=";
const issuerDID = "did:earthid:testnet:HveP8Sc6xGJJSZ9sGdDZFdQ6zZM9JZjG5BSg6R1YNuFH;earthid:testnet:fid=0.0.407132";
const userDID = "did:earthid:testnet:9WzSyPSBkbUQVktjGxmvKAzbTNJCs1PFqo2fQ6BnuHiE;earthid:testnet:fid=0.0.15057499";




const Training = () => {
    const [responseData, setResponseData] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isVerificationLoading, setIsVerificationLoading] = useState([true, true, true, true, true]);
    const [verificationData, setVerificationData] = useState([]);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);
    const [showCertificate, setShowCertificate] = useState(true);
    //const [showJson, setShowJson] = useState(true);
    const { userName } = useParams();
    const qrCodeUrl = `https://university.myearth.id/certificate/marriage/${userName}`;

    const pdfContainerRef = useRef();


    const certificationName = 'University Degree Certificate';
    const certUrl = `https://university.myearth.id/certificate/marriage/${userName}`;






    useEffect(() => {


        const fetchData = async () => {
            try {
                const prefix = 'MarriageCertificate'; // Replace with your desired prefix
                const userNamee = `${prefix}${userName}`;
                console.log('Username', userNamee)
                const cidsResponse = await fetch(`https://bankdb.myearth.id/api/vcnew/company/${userNamee}/CertificateVc`);
                const cidJson = await cidsResponse.json();
                console.log('CidsResponse', cidJson);

                if (cidJson && cidJson.response && cidJson.response.length > 0) {
                    // const response = await fetch('https://bankdb.myearth.id/api/vc/fetchVCMain', {
                    //     method: 'POST',
                    //     headers: {
                    //         'Accept': 'application/vnd.ipld.car',
                    //         'Content-Type': 'application/json',
                    //     },
                    //     body: JSON.stringify({
                    //         cids: [
                    //             cidJson.cids[cidJson.cids.length - 1]
                    //         ],
                    //     }),
                    // });
setResponseData(cidJson.response)
                    // if (response.ok) {
                    //     const json = await response.json();
                    //     console.log(json);
                    //     setResponseData(json);
                    // } else {
                    //     console.log('Request failed with status code:', response.status);
                    // }
                } else {
                    console.log('Certificate not generated');
                    Swal.fire('Certificate Not Generated');
                }
            } catch (error) {
                console.log('Error:', error);
            }
            finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);





    useEffect(() => {
        if (isButtonClicked && responseData) {
            //   setIsLoading(true);
            setIsVerificationLoading([true, true, true, true, true]);
            setVerificationData([]);

            setTimeout(() => {
                //setIsLoading(false);
                setIsVerificationLoading([true, true, true, true, true]);
                setVerificationData([
                    moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY'),
                    "NA",
                    "EarthID",
                    userName
                ]);

                setTimeout(() => {
                    setIsVerificationLoading([false, true, true, true, true]);
                    setVerificationData([
                        moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY'),
                        "NA",
                        "EarthID",
                        userName
                    ]);

                    setTimeout(() => {
                        setIsVerificationLoading([false, false, true, true, true]);
                        setVerificationData([
                            moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY'),
                            "NA",
                            "EarthID",
                            userName
                        ]);

                        setTimeout(() => {
                            setIsVerificationLoading([false, false, false, true, true]);
                            setVerificationData([
                                moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY'),
                                "NA",
                                "EarthID",
                                userName
                            ]);

                            setTimeout(() => {
                                setIsVerificationLoading([false, false, false, false, true]);
                                setVerificationData([
                                    moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY'),
                                    "NA",
                                    "EarthID",
                                    userName
                                ]);

                                setTimeout(() => {
                                    setIsVerificationLoading([false, false, false, false, false]);
                                    setVerificationData([
                                        moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY'),
                                        "NA",
                                        "EarthID",
                                        userName
                                    ]);
                                }, 1000);
                            }, 1000);
                        }, 1000);
                    }, 1000);
                }, 1000);
            }, 12000);
        }
    }, [isButtonClicked, responseData]);

    const getYearandMonth = (responseData) => {
        if (responseData && responseData.length > 0) {
            const issueYear = moment(responseData[0].vc.issuanceDate).format('YYYY')
            console.log(issueYear)
            const issueMonth = moment(responseData[0].vc.issuanceDate).format('MM')
            console.log(issueMonth)

            const expiryYear = moment(responseData[0].vc.expirationDate).format('YYYY')
            console.log(expiryYear)
            const expiryMonth = moment(responseData[0].vc.expirationDate).format('MM')
            console.log(expiryMonth)
            // Return an object containing the formatted values
            return {
                issueYear,
                issueMonth,
                expiryYear,
                expiryMonth,
            };
        }
        return null;
    }

    const dateData = getYearandMonth(responseData)
    console.log(dateData)

    const generateLinkedInURL = (certificationName, dateData, certUrl) => {
        if (!dateData) {
            // Handle the case when dateData is null (responseData is not available or empty)
            console.error("dateData is null. Cannot generate LinkedIn URL.");
            return null;
        }

        const linkedInURL = `https://www.linkedin.com/profile/add?startTask=${encodeURIComponent(
            certificationName
        )}&name=Marriage%20Certificate&issuingOrganization=EarthID&issueYear=${encodeURIComponent(dateData.issueYear)}&issueMonth=${encodeURIComponent(
            dateData.issueMonth
        )}&expirationYear=${encodeURIComponent(dateData.expiryYear)}&expirationMonth=${encodeURIComponent(
            dateData.expiryMonth
        )}&certUrl=${encodeURIComponent(certUrl)}`;

        return linkedInURL;
    };

    const linkedInURL = generateLinkedInURL(
        certificationName,
        dateData,
        certUrl
    );



    const extractLastPart = (responseData) => {
        if (responseData && responseData.length > 0 && responseData[0].vc.id) {
            const id = responseData[0].vc.id;
            const parts = id.split('-');
            const lastPart = parts[parts.length - 1];
            return lastPart;
        }
        return null;
    }

    const lastPart = extractLastPart(responseData);

    //Verify Api

    const createUserSignature = async (documentMetadata) => {
        try {
            const userDid = await createUserDid();
            const payload = {
                payload: {
                    credentialSubject: {
                        id: userDid,
                        pdfMetaData: documentMetadata,
                    },
                },
            };

            const config = {
                method: 'post',
                url: `${ssiBaseUrl}/api/user/sign?issuerDID=${issuerDID}`,
                headers: {
                    'X-API-KEY': authorizationKey,
                    privateKey,
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(payload),
            };

            const response = await axios.request(config);
            console.log(response.data)
            return response.data.Signature;
        } catch (error) {
            console.log(error);
            throw error;
        }
    };


    const verifyVC = async () => {
        try {

            //const signature = await createUserSignature();

            const data = {
                "credentials": {
                    ...responseData[0].vc
                }
            }
            const config = {
                method: 'post',
                url: ssiBaseUrl + "/api/verifier/verify?zpkValidation=false",
                headers: {
                    'X-API-KEY': authorizationKey,
                    'publicKey': publicKey,
                    //'userSignature': signature, // Call the createUserSignature function
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data)
            };

            const response = await axios.request(config);
            console.log(JSON.stringify(response.data));

            return response.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }


    const createUserDid = async () => {
        // Implement the logic to create a user DID (if required)
        // For now, returning a placeholder value
        return 'did:earthid:testnet:9WzSyPSBkbUQVktjGxmvKAzbTNJCs1PFqo2fQ6BnuHiE;earthid:testnet:fid=0.0.15057499';
    };

    //Verify Api

    const handlePopupOpen = () => {
        setIsPopupOpen(true);
        setIsButtonClicked(true);
        setIsVerifying(true);
        

        // Call verifyVC() here
        verifyVC().then((verificationResult) => {
            // Process the verification result and update state variables accordingly
            setIsVerifying(false);
            // Update other state variables based on verification result
            // Example:
            // setIsVerificationLoading([...]);
            // setVerificationData([...]);
        }).catch((error) => {
            // Handle error
            setIsVerifying(false);
            // Display error message or take appropriate action
        });
    };

    const handlePopupClose = () => {
        setIsPopupOpen(false);
    };

    // function toggleView() {
    //     setShowJson(!showJson);
    //   }

    const handleViewCertificate = () => {
        const MySwal = withReactContent(Swal);

        MySwal.fire({
            title: 'Digital Certificate(VC)',
            html: <pre>{JSON.stringify(responseData[0].vc, null, 2)}</pre>,
            confirmButtonText: 'Verify',
            showCancelButton: true,
            cancelButtonText: 'Close',
            customClass: {
                content: 'certificate-swal',
                confirmButton: 'swal-button',
                cancelButton: 'swal-button swal-button--cancel',
            },
            width: '1000px',
            didOpen: () => {
                const verifyButton = MySwal.getConfirmButton();
                verifyButton.addEventListener('click', () => {
                    const loadingSwal = MySwal.mixin({
                        title: 'Loading',
                        text: 'Performing verification...',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        showConfirmButton: false,
                        onOpen: () => {
                            loadingSwal.showLoading();
                            verifyVC()
                                .then(() => {
                                    // Display success message upon successful verification
                                    loadingSwal.close();
                                    MySwal.fire({
                                        title: 'Verification Successful',
                                        text: '',
                                        icon: 'success',
                                        showConfirmButton: true,
                                        confirmButtonText: 'OK',
                                        customClass: {
                                            confirmButton: 'swal-button',
                                        },
                                    });
                                })
                                .catch((error) => {
                                    // Handle error case and display error message
                                    loadingSwal.close();
                                    MySwal.fire({
                                        title: 'Verification Error',
                                        text: 'An error occurred during verification.',
                                        icon: 'error',
                                        confirmButtonText: 'OK',
                                        allowOutsideClick: true,
                                        allowEscapeKey: true,
                                        showCancelButton: false,
                                        customClass: {
                                            confirmButton: 'swal-button',
                                        },
                                    });
                                });
                        },
                    });

                    loadingSwal.fire();
                });
            },
        });
    };

    const handleToggleCertificate = () => {
        setShowCertificate(true);
    };

    const handleToggleBadge = () => {
        setShowCertificate(false);
    };

    // Function to handle the PDF download
    const handleDownload = () => {
        const pdfContainer = pdfContainerRef.current;

        // Create a new jsPDF instance with the A4 landscape dimensions
        const pdf = new jsPDF('l', 'pt', [540, 740]);

        // Use html2canvas to render the content of the container as an image
        html2canvas(pdfContainer, { scale: 3, useCORS: true }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg', 1.0);
            pdf.addImage(imgData, 'JPEG', 0, 0, 740, 540);
            pdf.save(`marriage-certificate(${userName}).pdf`);
            // Open the PDF in a new window
            //   const pdfWindow = window.open('', '_blank');
            //   pdfWindow.document.write('<iframe width="100%" height="100%" src="' + pdf.output('datauristring') + '"></iframe>');
        });
    };

    const handleShareLinkedIn = () => {
        // Open the LinkedIn URL in a new tab
        window.open(linkedInURL, '_blank');
    };
    // const handleShareLinkedIn = () => {
    //     // Wait until the LinkedIn SDK is fully loaded
    //     window.onload = function () {
    //       // Access the IN object and use it here
    //       IN.UI.Share().params({
    //         url: 'YOUR_LINKEDIN_SHARE_URL',
    //         mini: true,
    //       }).place();
    //     };
    //   };
    //   const handleViewCertificate = () => {
    //     const MySwal = withReactContent(Swal);

    //     MySwal.fire({
    //       title: 'Digital Certificate(VC)',
    //       html: <pre>{JSON.stringify(responseData[0], null, 2)}</pre>,
    //       confirmButtonText: 'Close',
    //       customClass: {
    //         content: 'certificate-swal',
    //       },
    //     });
    //   };


    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            {responseData && responseData.length > 0 ? (
                <div className="page-container">
                    <nav className="navbar-cert">
                        <div className="logo">
                            <img src={logo}></img>
                        </div>
                        <ul className="nav-menu">
                            <li className="nav-item"><a href={`/certificate/marriage/${userName}`}>Home</a></li>
                        </ul>
                    </nav>
                    <div className="certificate-container">
                        <section className={'certBackk'}>
                            <div className='row'>
                                {/* <div className="icon-wrapper">
                                    <img
                                        src={CertificateIcon}
                                        alt="Certificate Icon"
                                        className={showCertificate ? 'active-icon' : 'inactive-icon'}
                                        onClick={handleToggleCertificate}
                                    />
                                    <img
                                        src={BadgeImg}
                                        alt="Badge Icon"
                                        //style={{ height: '150px', width: '130px' }}
                                        className={!showCertificate ? 'active-icon' : 'inactive-icon'}
                                        onClick={handleToggleBadge}
                                    />
                                </div> */}
                                
                                    <div ref={pdfContainerRef} className="certificated">
                                        <div className="bodbox">

                                            <div className="header">
                                                <div className="favico">
                                                    <img src={favicon}></img>
                                                </div>
                                                <div className="title">Marriage Certificate</div>
                                            </div>
                                            {/* <div className="title1">Year 2023-2024</div> */}
                                            <div className="title1">This certificate verifies that</div>

                                            <div className="body">
                                                <div className="name">Mr. {userName}</div>
                                                &
                                                <div className="name">Mrs. {userName}</div>
                                                <div className="title1">has succesfully gone through the marraige registration <br></br>has completed all the necessary processes.<br></br>This certificate is a verifiable credential and <br></br>
                                                    is issued on: {moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY, h:mm:ss A')}.
                                                </div>
                                            </div>
                                            <div>
                                                {/* Add the QRCode component */}
                                                <div className="qrcode-container">
                                                    <QRCode className='qrCode' value={qrCodeUrl} />
                                                </div>
                                                {/* <div className='signature'>EarthID</div> */}
                                                {/* <div className='signature1'>KPMG</div> */}
                                            </div>


                                        </div>
                                    </div>
                            </div>

                        </section>
                    </div>
                    {/* Add the PDF download button */}
                    {/* Add the download button */}

                    <section className='infosec'>
                        <div >
                            <div className='row'>
                                <div className='leftsec col-md-8'>
                                    <a href='https://myearth.id' target='blank'>
                                        <div className='issuerName'>

                                            <FontAwesomeIcon className='issuerfontIcon1' icon={faMedal} />

                                            EarthID

                                            <FontAwesomeIcon className='issuerfontIcon' icon={faArrowUpRightFromSquare} />


                                        </div>
                                    </a>

                                    <div className='row'>
                                        <div className="check col-md-1">
                                            <img src={check}></img>
                                        </div>
                                        <div className='coursename col-md-11'>Marriage Certificate</div>
                                    </div>
                                    <div className='allBtns row'>
                                        <button className='pdfbtn' title='Download Pdf' onClick={handleDownload}>
                                            <FontAwesomeIcon className='fontIcon' icon={faFilePdf} />
                                            PDF
                                        </button>
                                        {/* <button className='pdfbtn' title='Feature Currently Disabled'>
                                            <FontAwesomeIcon className='fontIcon' icon={faImage} />
                                            BADGE
                                        </button> */}
                                        <button className='pdfbtn' title='Feature Currently Disabled'>
                                            <FontAwesomeIcon className='fontIcon' icon={faShare} />
                                            EMAIL
                                        </button>
                                        <button className='pdfbtn' title='Feature Currently Disabled'>
                                            <FontAwesomeIcon className='fontIcon' icon={faPen} />
                                            EMBED
                                        </button>
                                        <button className='pdfbtn' title='Feature Currently Disabled'>
                                            <FontAwesomeIcon className='fontIcon' icon={faQuestionCircle} />
                                            HELP
                                        </button>
                                        <button className='pdfbtn' title='Feature Currently Disabled'>

                                            MORE
                                            <FontAwesomeIcon className='fontIcon1' icon={faCaretDown} />
                                        </button>
                                    </div>
                                    <div className='studNameDiv'>
                                        <FontAwesomeIcon className='fontIcon' icon={faUser} />
                                        {userName}
                                    </div>
                                    {/* <a href='' title='Feature Currently Disabled' onClick={(e) => e.preventDefault()}>
                                        <div className='viewCreds'>View All Credentials</div>
                                    </a> */}


                                    {/* <div className='stuID'>ID: EarthID</div> */}


                                    <hr></hr>
                                    <div className='description'>
                                    This certificate affirms the union of Mr. {userName} and Mrs. {userName}, joined in matrimony.

In celebration of love and commitment, this certificate records the marriage of Mr. {userName} and Mrs. {userName}.
                                    </div>
                                    {/* <div className='skillsdiv'>
                                        <p>Skills/Knowledge</p>
                                        <div className='row'>
                                            <div className='allSkills'>
                                                Javascript
                                            </div>
                                            <div className='allSkills'>
                                                React
                                            </div>
                                            <div className='allSkills'>
                                                Node
                                            </div>
                                            <div className='allSkills'>
                                                Hashgraph
                                            </div>

                                        </div>
                                    </div> */}
                                    <hr></hr>

                                    <div className='row'>
                                        <div className='issueon col-md-6'>
                                            <div>Issued On</div>
                                            <div>{moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY, h:mm:ss A')}</div>
                                        </div>
                                        <div className='expiry col-md-6'>
                                            <div>Expires On</div>
                                            <div>NA</div>
                                        </div>
                                    </div>

                                </div>
                                <div className='rightsec col-md-4'>
                                   
                                    <div className="footer2">
                                        <div className='verify'>Certificate Verification</div>
                                        <div className='verify1'>
                                            <FontAwesomeIcon className='fontIcon21' icon={faCheckCircle} />
                                            This certificate is from a <span> verified issuer</span>
                                        </div>
                                        <div className='verify1'>
                                        {/* <div className="check">
                                            <img src={check}></img>
                                        </div> */}
                                        <FontAwesomeIcon className='fontIcon21' icon={faCheckCircle} />
                                            Certificate ID: <span>{lastPart}</span>
                                        </div>
                                        <button className="verify-btn" onClick={handlePopupOpen}>
                                            Verify
                                        </button>
                                        <button className='verify-btn' onClick={handleViewCertificate}>View Digital Certificate</button>
                                    </div>
                                    <div className="footer2">
                                        <div className='verify'>More about the Issuer</div>
                                        <div className='verify1'>EarthID</div>
                                        <button className="verify-btn" onClick={() => window.open('https://myearth.id', '_blank', 'noopener')} rel="noopener">
                                            Visit Issuer Website
                                            <FontAwesomeIcon className='issuerfontIcon' icon={faArrowUpRightFromSquare} />
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </section>

                    {isPopupOpen && (
                        <div className="popup-container">
                            {isVerifying ? (
                                <div>
                                    <div className="spinner"></div>
                                    <div className="spinner-text">Verifying Credentials...</div>
                                </div>
                            ) : (
                                <div className="popup">
                                    <div className="popup-header">
                                        <div className="popup-title">Verification Details</div>
                                        <button className="popup-close" onClick={handlePopupClose}>
                                            X
                                        </button>
                                    </div>
                                    <hr></hr>
                                    <div className="popup-body">
                                        {isVerificationLoading[0] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Verifying Issuer...</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success">&#10004;</div>
                                                    </div>
                                                    {/* <div className="verification-label">Issuer is Valid:</div> */}

                                                    <div className="verification-result1">
                                                        <div>This issuer is verified by EarthID</div>
                                                        <div>The issuer is found to be valid</div>

                                                    </div>
                                                </div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        {/* <div className="success">&#10004;</div> */}

                                                    </div>
                                                    {/* <div className="verification-label">Issuer is Valid:</div> */}

                                                    <div className="verification-result1 row">

                                                        <a href='https://myearth.id' target='blank'>
                                                            <div className='issuerNamepop'>

                                                                <FontAwesomeIcon className='issuerfontIcon1' icon={faMedal} />

                                                                EarthID

                                                                <FontAwesomeIcon className='issuerfontIcon' icon={faArrowUpRightFromSquare} />


                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}
                                        {isVerificationLoading[1] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Checking Certificate ID...</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success">&#10004;</div>
                                                    </div>
                                                    {/* <div className="verification-label">Issued by:</div> */}

                                                    <div className="verification-result1">
                                                        {/* {verificationData[1] && <div>{verificationData[1]}</div>} */}
                                                        <div>The authenticity of this credential can be independently verified.</div>

                                                    </div>
                                                </div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        {/* <div className="success">&#10004;</div> */}

                                                    </div>
                                                    {/* <div className="verification-label">Issued by:</div> */}

                                                    <div className="verification-result1">
                                                        {/* {verificationData[1] && <div>{verificationData[1]}</div>} */}
                                                        <div className="footer22">
                                                            <div className='verify1'>
                                                            <FontAwesomeIcon className='fontIcon22' icon={faCheckCircle} />
                                                                Certificate ID: <span>{lastPart}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}
                                        {isVerificationLoading[2] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Verifying Owner...</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success">&#10004;</div>
                                                    </div>


                                                    <div className="verification-result1">
                                                        <div>The owner of this credential has been verified</div>
                                                        <div className='row'>
                                                            <div className="verification-label">Issued to:</div>
                                                            {verificationData[3] && <div>{verificationData[3]}</div>}
                                                        </div>

                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}
                                        {isVerificationLoading[3] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Checking Certificate Status...</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className='datess row'>
                                                    <div className="verification-itemm">
                                                        <div className="verification-result">
                                                            {/* <div className="success">&#10004;</div> */}
                                                            <div></div>
                                                        </div>
                                                        <div>
                                                            <div className="verification-label">Issued on:</div>

                                                            <div className="verification-result1">
                                                                {verificationData[0] && <div>{verificationData[0]}</div>}
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="verification-itemm">
                                                        <div className="verification-result">
                                                            {/* <div className="success">&#10004;</div> */}
                                                            <div></div>
                                                        </div>
                                                        <div>
                                                            <div className="verification-label">Expires on:</div>

                                                            <div className="verification-result1">
                                                                {verificationData[1] && <div>{verificationData[1]}</div>}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}
                                        {isVerificationLoading[4] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Verifying Credential Data...</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success1"><img src={check}></img></div>
                                                    </div>
                                                    <div className="verification-label"></div>

                                                    <div className="verification-result1">
                                                        <div className='verified'>This Marriage Certificate is VERIFIED</div>

                                                        <div className='verified1'>This digital credential was securely issued via EarthID and its information is valid.</div>


                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}
                                        
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    <footer className="footerr">
                        <div className="footerr-text">
                            © 2023 EarthID, Inc. All Rights Reserved.
                        </div>
                    </footer>
                </div>
            ) : (
                Swal.fire({
                    title: "No Certificate Found",
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonText: "Go Back",
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Redirect to the home page
                        window.location.href = "/uploadDoc";
                    }
                })
            )}
        </>

    );
};

export default Training;
