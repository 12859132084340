import React, { Component } from "react";
import openSocket from "socket.io-client";
import CryptoJS from "react-native-crypto-js";
import crypto, { createHmac } from "crypto";
import data from "./api";
import { store } from "react-notifications-component";
import "./App.css";
import Swal from "sweetalert2";
import { SHA256 } from "crypto-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faCaretDown, faClose } from "@fortawesome/free-solid-svg-icons";
import logo from "./images/images/earthid-logo.png"
import withReactContent from 'sweetalert2-react-content';



// export const isTestnet = true;
// const socketURL =
//   isTestnet === true
//     ? "https://socket-stagev2.myearth.id"
//     : "https://socketv2.myearth.id";
// const socket = openSocket(socketURL, {
//   transports: ["websocket", "polling", "flashsocket"],
// });
// let socketId;
// socket.on("connect", () => {
//   socketId = socket.id;
//   //console.log(`I'm connected with the back-end`);
// });

const {
  listenForUserData,
  listenForServiceProviderResponse,
  getApiKey,
  generateQrCodeNest,
  getUserCreditnals,
  generateqr,
  registerVendor,
} = data;

let showNotification = true;


class KycFlow extends Component {
  
  state = {
    showSpinner: false,
    showBtn: true,
    requestNo: "",
    qrcode: false,
    response: false,
    email: "",
    mno: "",
    profileDrop: false,
    btnText: "Login with Earth Id",
    showText: true,
    showModal: true,
    modalHeading: false,
    createDid: "",
    creditnals: "",
    isSubmitDisabled: true,
  };



  loader = () => {
    this.setState({ showBtn: false, modalHeading: true });
    this.setState({ qrcode: true, showSpinner: false });
    if (!this.state.showModal) {
      this.setState({
        showModal: true,
      });
    }
    if (this.state.loggedIn) {
      localStorage.removeItem("email");
      localStorage.removeItem("mobile_no");
      localStorage.removeItem("user_name");
      localStorage.removeItem("dob");
      localStorage.removeItem("e_verified");
      localStorage.removeItem("trust_score");
      localStorage.removeItem("earth_id");
      localStorage.removeItem("m_verified");
      this.setState({
        loggedIn: false,
        btnText: "Login with Earth Id",
        qrcode: true,
        response: false,
        showModal: false,
      });
      setTimeout(() => {
        document.getElementById("exampleModal").click();
      }, 0);
      this.redirectToHome();
    }
  };

  componentDidMount = async () => {
    if (localStorage.email != null) {
        this.setState({
            loggedIn: true,
            btnText: "LogOut",
        });
    }
    if (!this.state.apiKey) {
        this.state.apiKey = getApiKey();
    }
};

updateNotificationState = () => {
    setTimeout(() => {
        showNotification = true;
    }, 100);
};

verifyNotificationState = () => {
    setTimeout(() => {
        showNotification = true;
    }, 100);
};

userDenied = () => {
    this.setState({
        response: false,
        showModal: false,
    });
    setTimeout(() => {
        document.getElementById("exampleModal").click();
        if (showNotification === true) {
            showNotification = false;
            store.addNotification({
                title: "Error!",
                message: `User Didn't Authorize!`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true,
                },
            });
        }
    }, 0);
};

listenForSocket = () => {
    listenForServiceProviderResponse((err, res) => {
        this.setState({
            qrcode: false,
            response: false,
            showSpinner: true,
            showText: false,
        });
    });

  listenForUserData(async (err, res) => {
        let bytes = res.userDetails || res.newreq;
        //sessionStorage.setItem('profileData', JSON.stringify(bytes));
console.log('Got zkp data', bytes)
        console.log()
        console.log("bytes", bytes);
        console.log("Response", res);
        this.setState({
            showQr: false,
            showSpinner: false,
        });
        let creditnals;
        let sessionKey;
        if (bytes === "denied" || bytes.permission === "denied") {
            this.userDenied();
        } else if (
            bytes.permission === "granted" ||
            bytes === "issuerLogin" ||
            bytes === "credentialsgenerated" ||
            bytes === "verifylogin" ||
            bytes === "success"
        ) {
            this.setState({
                response: true,
                showSpinner: false,
                loggedIn: true,
                showModal: false,
                btnText: "LogOut",
                bytes: "credentialsgenerated",
            });
            setTimeout(() => {
                document.getElementById("exampleModal").click();
                let popMessage;
                // if (showNotification === true) {
                //     if (this.state.bytes === "credentialsgenerated") {
                //         popMessage = "Credentials Generated";
                //     } else {
                //         popMessage = `Login Successful`;
                //     }
                //     showNotification = false;
                //     store.addNotification({
                //         title: "Success!",
                //         message: popMessage,
                //         type: "success",
                //         insert: "top",
                //         container: "top-right",
                //         animationIn: ["animated", "fadeIn"],
                //         animationOut: ["animated", "fadeOut"],
                //         dismiss: {
                //             duration: 3000,
                //             onScreen: true,
                //         },
                //     });
                //     if (bytes.permission === "granted") {
                //         this.updateNotificationState();
                //     } else if (bytes === "verifylogin") {
                //         this.verifyNotificationState();
                //     }
                // }
            }, 0);
        } else if (bytes.userDetails.credentialType != null) {
            console.log("bytes", bytes);
            sessionKey = bytes.sessionKey;
            await getUserCreditnals(bytes.userDetails)
                .then((data) => {
                    this.userCreditnalsSuccess(bytes);

                    creditnals = data;
                })
                .catch((err) => {
                    alert(err);
                });
        } else {
            this.setState({
                response: false,
                showModal: false,
            });
            setTimeout(() => {
                document.getElementById("exampleModal").click();
                if (showNotification === true) {
                    showNotification = false;
                    store.addNotification({
                        title: "Error!",
                        message: `User Didn't Authorize!`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true,
                        },
                    });
                }
            }, 0);
        }
    });
};

userCreditnalsSuccess = (data) => {
    if (data.userDetails.credentialType != null) {
        this.setState({
            response: true,
            showSpinner: false,
            loggedIn: true,
            showModal: false,
            btnText: "LogOut",
        });
        setTimeout(() => {
            document.getElementById("exampleModal").click();
            let popMessage;
            if (showNotification === true) {
                popMessage = "Credentials Generated";
                showNotification = false;
                store.addNotification({
                    title: "Success!",
                    message: popMessage,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true,
                    },
                });
            } else {
                this.setState({
                    response: false,
                    showModal: false,
                });
                setTimeout(() => {
                    document.getElementById("exampleModal").click();
                    if (showNotification === true) {
                        showNotification = false;
                        store.addNotification({
                            title: "Error!",
                            message: `User Didn't Authorize!`,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 3000,
                                onScreen: true,
                            },
                        });
                    }
                }, 0);
            }
        }, 0);
    }
};


/********* KYC function ************/  
QrCodeDataKYC = () => {
  registerVendor()
      .then((d) => {
          const timestamp = Date.now();
          const hash = crypto
              .createHmac("sha256", d.secretKey)
              .update(JSON.stringify(d.apiKey + "Rejolut@1008" + timestamp))
              .digest("hex");
        console.log('apikey', d.secretKey)
          generateQrCodeNest(
              hash,
              d.apiKey,
              "Rejolut@1008",
              timestamp,
              "document"
          )
              .then((data) => {
                console.log('data', data)
                  this.listenForSocket();
                  this.setState({
                      showQr: true,
                      qrCodeData: data,
                      apiKey: this.state.apikey,
                      qrcode: false,
                  });
                  this.props.onComplete();
                  console.log("test");
              })
              .catch((err) => {
                  alert(err);
              });
      })
      .catch((err) => {
          alert(err);
      });
};




qrcode = () => {
    let { qrCodeData } = this.state;
    return (
        <div className="qr-container">
            <img src={qrCodeData} alt="new" />
        </div>
    );
};

redirectToHome() {
    window.location.href = "/";
}
redirectToGenerateCred() {
    window.location.href = "/generateCred";
}

redirectToVerifyCreditnals() {
    window.location.href = "/verifyCredentials";
}


/********* SDD function ************/  
QrCodeDataSDD = () => {
  registerVendor()
      .then((d) => {
          const timestamp = Date.now();
          const hash = crypto
              .createHmac("sha256", d.secretKey)
              .update(JSON.stringify(d.apiKey + "Rejolut@1008" + timestamp))
              .digest("hex");
        console.log('apikey', d.secretKey)
          generateQrCodeNest(
              hash,
              d.apiKey,
              "Rejolut@1008",
              timestamp,
              "selectiveData"
          )
              .then((data) => {
                console.log('data', data)
                  this.listenForSocket();
                  this.setState({
                      showQr: true,
                      qrCodeData: data,
                      apiKey: this.state.apikey,
                      qrcode: false,
                  });
                  this.props.onComplete();
                  console.log("test");
              })
              .catch((err) => {
                  alert(err);
              });
      })
      .catch((err) => {
          alert(err);
      });
};


/********* ZKP function ************/  

listenForZKPSocket = (zkpData) => {
  listenForServiceProviderResponse((err, res) => {
      this.setState({
          qrcode: false,
          response: false,
          showSpinner: true,
          showText: false,
      });
  });

listenForUserData(async (err, res) => {
      let bytes = res.userDetails || res.newreq;
      //sessionStorage.setItem('profileData', JSON.stringify(bytes));
console.log('Got zkp data', bytes)
      console.log()
      console.log("bytes", bytes);
      console.log("Response", zkpData);
      this.setState({
          showQr: false,
          showSpinner: false,
      });
      let creditnals;
      let sessionKey;

      const MySwal = withReactContent(Swal);

    // Check if certificate object exists and has properties
if (bytes.certificate && typeof bytes.certificate === 'object' && Object.keys(bytes.certificate).length > 0) {
  console.log('Certificate object exists with properties:');

  // Loop through each property in the certificate object
  for (const property in bytes.certificate) {
    if (Object.hasOwnProperty.call(bytes.certificate, property)) {
      const value = bytes.certificate[property];
console.log('zkpcert', zkpData)
      // Perform actions based on the property and its value
      console.log(`Property: ${property}, Value: ${value}`);
//const zkpStringData = JSON.stringify(zkpData)
      // Example: Check if the value is true

if(property == "dateOfBirth" && value === true && zkpData.request=="minAge"){
  MySwal.fire({
    icon: 'success',
    title: `Successfully verified that the user's age is above ${zkpData.value}`,
    showCloseButton: true,
    showCancelButton: false,
    focusConfirm: false,
    confirmButtonText: 'Close',
    onClose: () => {
      // Add your onClick logic here
      this.refreshPage()
      // You can perform additional actions when the close button is clicked
    },
  });
}else if(property == "balance" && value === true && property == zkpData.request){
  MySwal.fire({
    icon: 'success',
    title: `Successfully verified that the user has a minimum balance of ${zkpData.minimum}`,
    showCloseButton: true,
    showCancelButton: false,
    focusConfirm: false,
    confirmButtonText: 'Close',
    onClose: () => {
      // Add your onClick logic here
      this.refreshPage()
      // You can perform additional actions when the close button is clicked
    },
  });
}else {
  console.log(`Value of ${property} is false. Do something for false.`);
  MySwal.fire({
    icon: 'error',
    title: `Verification Failed`,
    showCloseButton: true,
    showCancelButton: false,
    focusConfirm: false,
    confirmButtonText: 'Close',
    onClose: () => {
      // Add your onClick logic here
      this.refreshPage()
      // You can perform additional actions when the close button is clicked
    },
  });
}


      // if (value === true) {
      //   console.log(`Value of ${property} is true. Do something for true.`);
      //   if(zkpData.value){
      //     MySwal.fire({
      //       icon: 'success',
      //       title: `Successfully verified that the user's age is above ${zkpData.value}`,
      //       showCloseButton: true,
      //       showCancelButton: false,
      //       focusConfirm: false,
      //       confirmButtonText: 'Close',
      //       onClose: () => {
      //         // Add your onClick logic here
      //         this.refreshPage()
      //         // You can perform additional actions when the close button is clicked
      //       },
      //     });
      //   }else if(zkpData.minimum){
      //     MySwal.fire({
      //       icon: 'success',
      //       title: `Successfully verified that the user has a minimum balance of ${zkpData.minimum}`,
      //       showCloseButton: true,
      //       showCancelButton: false,
      //       focusConfirm: false,
      //       confirmButtonText: 'Close',
      //       onClose: () => {
      //         // Add your onClick logic here
      //         this.refreshPage()
      //         // You can perform additional actions when the close button is clicked
      //       },
      //     });
      //   }
        
      // } else {
      //   console.log(`Value of ${property} is false. Do something for false.`);
      //   MySwal.fire({
      //     icon: 'error',
      //     title: `Verification Failed`,
      //     showCloseButton: true,
      //     showCancelButton: false,
      //     focusConfirm: false,
      //     confirmButtonText: 'Close',
      //     onClose: () => {
      //       // Add your onClick logic here
      //       this.refreshPage()
      //       // You can perform additional actions when the close button is clicked
      //     },
      //   });
      // }
    }
  }
} else {
  console.log('Certificate object does not exist or is empty.');
  MySwal.fire({
    icon: 'error',
    title: 'Certificate not found. Please retry.',
    showCloseButton: true,
    showCancelButton: false,
    focusConfirm: false,
    confirmButtonText: 'Close',
    onClose: () => {
      // Add your onClick logic here
      this.refreshPage()
      // You can perform additional actions when the close button is clicked
    },
  });
}




      if (bytes === "denied" || bytes.permission === "denied") {
          this.userDenied();
      } else if (
          bytes.permission === "granted" ||
          bytes === "issuerLogin" ||
          bytes === "credentialsgenerated" ||
          bytes === "verifylogin" ||
          bytes === "success"
      ) {
          this.setState({
              response: true,
              showSpinner: false,
              loggedIn: true,
              showModal: false,
              btnText: "LogOut",
              bytes: "credentialsgenerated",
          });
          setTimeout(() => {
              document.getElementById("exampleModal").click();
              let popMessage;
              // if (showNotification === true) {
              //     if (this.state.bytes === "credentialsgenerated") {
              //         popMessage = "Credentials Generated";
              //     } else {
              //         popMessage = `Login Successful`;
              //     }
              //     showNotification = false;
              //     store.addNotification({
              //         title: "Success!",
              //         message: popMessage,
              //         type: "success",
              //         insert: "top",
              //         container: "top-right",
              //         animationIn: ["animated", "fadeIn"],
              //         animationOut: ["animated", "fadeOut"],
              //         dismiss: {
              //             duration: 3000,
              //             onScreen: true,
              //         },
              //     });
              //     if (bytes.permission === "granted") {
              //         this.updateNotificationState();
              //     } else if (bytes === "verifylogin") {
              //         this.verifyNotificationState();
              //     }
              // }
          }, 0);
      } else if (bytes.userDetails.credentialType != null) {
          console.log("bytes", bytes);
          sessionKey = bytes.sessionKey;
          await getUserCreditnals(bytes.userDetails)
              .then((data) => {
                  this.userCreditnalsSuccess(bytes);

                  creditnals = data;
              })
              .catch((err) => {
                  alert(err);
              });
      } else {
          this.setState({
              response: false,
              showModal: false,
          });
          setTimeout(() => {
              document.getElementById("exampleModal").click();
              if (showNotification === true) {
                  showNotification = false;
                  store.addNotification({
                      title: "Error!",
                      message: `User Didn't Authorize!`,
                      type: "danger",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                          duration: 3000,
                          onScreen: true,
                      },
                  });
              }
          }, 0);
      }
  });
};

QrCodeDataZKP = (zkpData) => {

  console.log('QrZKPData', zkpData)
  const zkpDataString = JSON.stringify(zkpData);
  const jsonStringWithSingleQuotes = zkpDataString.replace(/"/g, "'");

  console.log(jsonStringWithSingleQuotes);
  registerVendor()
      .then((d) => {
          const timestamp = Date.now();
          const hash = crypto
              .createHmac("sha256", d.secretKey)
              .update(JSON.stringify(d.apiKey + "Rejolut@1008" + timestamp))
              .digest("hex");
        console.log('apikey', d.secretKey)
          generateQrCodeNest(
              hash,
              d.apiKey,
              "Rejolut@1008",
              timestamp,
              jsonStringWithSingleQuotes
          )
              .then((data) => {
                console.log('data', data)
                  this.listenForZKPSocket(zkpData);
                  this.setState({
                      showQr: true,
                      qrCodeData: data,
                      apiKey: this.state.apikey,
                      qrcode: false,
                  });
                  this.props.onComplete();

                  console.log("test");
              })
              .catch((err) => {
                  alert(err);
              });
      })
      .catch((err) => {
          alert(err);
      });
};


openPopupBox = () => {
  const MySwal = withReactContent(Swal);

  MySwal.fire({
    title: 'Add ZKP Condition',
    html: (
      <div>

{/* <form class="was-validated">
  <div class="mb-3">
    <label for="validationTextarea">Textarea</label>
    <textarea class="form-control is-invalid" id="validationTextarea" placeholder="Required example textarea" required></textarea>
    <div class="invalid-feedback">
      Please enter a message in the textarea.
    </div>
  </div>
  </form> */}

        {/* Dropdown */}
        <label className="custom-title" htmlFor="dropdown">Select the condition for ZKP:</label>
        <br />
        <select id="dropdown" className="custom-dropdown">
        <option value="">Select...</option>
          <option value="value">Age</option>
          <option value="minimum">Balance</option>
          {/* Add more options as needed */}
        </select>
        <br />
        {/* Input text box */}
        <label htmlFor="inputText">Enter minimum value for the condition:</label>
        <br />
        <input type="text" id="inputText" className="custom-input"/>
        <br />
        {/* Buttons */}
        
        <button
          className="custom-button"
          data-toggle="modal"
  data-target="#exampleModal"
          onClick={this.submitForm}
        >
          Submit
        </button>

        <button className="custom-button" onClick={this.refreshPage}>
          Close
        </button>
      </div>
    ),
    showCancelButton: false,
    showConfirmButton: false,
    focusConfirm: false,
    preConfirm: () => { 
    },
  })
};

// Function to close the SweetAlert modal
closePopupBox = () => {
  Swal.close();
};



// Function to handle form submission
submitForm = () => {
  // Collect the entire form data into a JSON object
  const formDataJson = {
    dropdownValue: document.getElementById('dropdown').value,
    inputValue: document.getElementById('inputText').value,
  };

  // Log the form data to the console
  console.log('Form Data:', formDataJson);
let zkpData
  if(formDataJson.dropdownValue == "value"){
    zkpData = {
      "request": "minAge",
      "type": "date",
    "value": formDataJson.inputValue,
    "unit": "years"
    }
  }else if(formDataJson.dropdownValue == "minimum"){
    zkpData = {
      "request": "balance",
      "type": "number",
    "minimum": formDataJson.inputValue
    }
  }else{
    console.log("No value selected!")
  }
console.log("ZkpData", zkpData)
// const jsonString = "{\"apikey\":\"EGZEUSHEUHF\",\"reqNo\":\"3336dc18-5c72-430b-b6f8-0e3c6a6a4df7\",\"sessionKey\":\"3336dc18-5c72-430b-b6f8-0e3c6a6a4df7\",\"requestType\":\"{\'request\':\'minAge\',\'type\':\'date\',\'value\':\'18\',\'unit\':\'years\'}\" }"
// const stringWithDoubleBackslashes = jsonString.replace(/'/g, '\\"');

// try {

//   const jsonObject = JSON.parse(stringWithDoubleBackslashes);

//   jsonObject.requestType = JSON.parse(jsonObject.requestType);

//   console.log('Parsed Object:', jsonObject);
// } catch (error) {
//   console.error('Error parsing JSON:', error);
// }
if(formDataJson.dropdownValue==''){
  Swal.showValidationMessage('Please select a condition for ZKP');
}else if(formDataJson.inputValue==''){
  Swal.showValidationMessage('Please enter a minimum value for the condition');
}
else{
  this.QrCodeDataZKP(zkpData)
  // Close the SweetAlert modal
  this.closePopupBox();

  
  // Trigger Bootstrap modal using JavaS
  // this.setState({ showModal: true }, () => {
  //   console.log('showModal state:', this.state.showModal);
  // });
  }



};

refreshPage = () => {
  window.location.reload();
};

  render() {
    let showButton = this.state.showSpinner ? (
      <div className="spinner-div">
        <img className="spinner" src={require("./spinner.gif")} />
      </div>
    ) : null;
    return (
      <>
<div className="App">
<nav className="navbar navbar-expand-lg navbar-light">
          <div
            className=" collapse navbar-collapse pull-right "
            id="navbarTogglerDemo01"
          >
            <div className="clickable" onClick={() => window.open("https://www.myearth.id/")}> 
              <img src={logo} alt="logo" className="logo"  />{" "}
            </div>
            <ul className=" navbar-nav mr-auto mt-2 mt-lg-0" style={{marginLeft:"1.5rem", textTransform:"uppercase"
            , color:"White"}}>
                    <li className="nav-item">
                        <a className="nav-link active" aria-current="page" href="#">Plans</a>
                    </li>
                    {/* <li className="nav-item">
                        <a className="nav-link" href="#">Services</a>
                    </li> */}
                    <li className="nav-item">
                        <a className="nav-link" href="#">Business</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">Investment</a>
                    </li>
            </ul>

            {localStorage.user_name ? (
              <div>
                <label className="welcomeLabel">{`Welcome, ${localStorage.getItem(
                  "user_name"
                )}`}</label>
              </div>
            ) : null}
        <div className="dropdown">
        <button
          className='nav-buttons dropdown-toggle'
          type='button'
          id='certificateDropdown'
          data-toggle='dropdown'
          aria-haspopup='true'
          aria-expanded='false'
          style={{ marginRight: "2rem", marginLeft: "2rem" }}
        >
          Identity Verification <FontAwesomeIcon className='dropdownfont' icon={faCaretDown} />
        </button> 
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <button
            type="button"
            className="dropdown-item"
            data-toggle="modal"
            data-target="#exampleModal"
            onClick={this.QrCodeDataKYC}
          >
            Identity Verification
          </button>
          <button
            type="button"
            className="dropdown-item"
            data-toggle="modal"
            data-target="#exampleModal"
            onClick={this.QrCodeDataSDD}
          >
            Verify Credentials
          </button>
          <button
            type="button"
            className="dropdown-item"
            onClick={this.openPopupBox}
          >
            Confirm Eligibility
          </button>
        </div>
      </div>
                    
                   
                  <button
                    type="button"
                    className="nav-buttons"
                    onClick={this.redirectToHome}
                  >
                    Logout
                  </button>

          <div className="modal_container" id="modal-container">
            <div className="modal">
              <div className="qr-code">
                <img
                  src='https://api.qrserver.com/v1/create-qr-code/?data={"apikey":"UKURGTREFATPSYJ","reqNo":"BAZQUVRDTLF","sessionKey":"BAZQUVRDTLF","requestType":"login","encryptionkey":"loginDetailsEncryption" }'
                  alt="QR-Code"
                />
              </div>

              <button id="btn-close">Close me</button>
            </div>
          </div>

          </div>
          </nav>
          </div>
     
         <div className="hero-section">
          <div className="shoppingCart"></div>
          <div
            className="modal fade"
            id="exampleModal"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            
          >
            <div className="modal-dialog" role="document">
              {
                <div>
                  {this.state.showModal && (
                    <div className="modal-content">
                              <div className="close-button-containerr">
                      <h4 style={{ color: "black" }}>
                          {/* {this.state.modalHeading && !this.state.showSpinner
                            ? "Use Earth ID app to scan the QR code"
                            : "Waiting for user authorization"} */}
                             Scan me using your wallet
                        </h4>
    <FontAwesomeIcon className="close-buttonn" data-dismiss="modal"  icon={faClose} />
 
</div>
                      <div className="modal-body">
                        <div className="pop-upbox">
                          {showButton}
                          {this.state.response
                            ? null
                            : this.state.qrcode
                              ? this.generateCredQrCodeData()
                              : null}
                          {this.state.showQr ? (
                            this.qrcode()
                          ) : this.state.showSpinner ? (
                            <img
                              className="spinner"
                              src={require("./spinner.gif")}
                            />
                          ) : null}
                        </div>
                      </div>
                      {/* <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary p-btn"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                      </div> */}
                    </div>
                  )}
                </div>
              }
            </div>
          </div>
 



        </div>




       

      </>
    );
  }
}

export default KycFlow;
