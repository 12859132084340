import React from 'react'
import "./baseui.css";

import {faTwitter, faFacebook, faYoutube, faLinkedinIn, faInstagram} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Navbar from '../../Navbar';
import Footer from '../Footer/Footer';
import credit_card from "../../images/assets/features/credit-card.png"
import explore from "../../images/assets/features/explore.png"
import help from "../../images/assets/features/help.png"
import investment from "../../images/assets/features/investment.png"

import facebook from "../../images/assets/footer/facebook.png"
import twitter from "../../images/assets/footer/twitter.png"
import instagram from "../../images/assets/footer/instagram.png"
import linkedin from "../../images/assets/footer/linkedin.png"
import youtube from "../../images/assets/footer/youtube.png"
import mail from "../../images/assets/footer/mail.png"


const Baseui = () => {
  return (
    <>
    <Navbar/>
    <div className="home-hero">
            <div className="hero__box">
              <h2 className="hero__box-feature">
                What we Do? Find more about EarthID
              </h2>
              <a
                href="https://www.youtube.com/channel/UCaRjLyN4I_0UrK3NL4zU1Vg/featured"
                target="blank"
                className="btn-know-us btn btn--white btn--animated"
              >
                Know us
              </a>
            </div>
          </div>

          <section className="features">
            <div className="feature-section">
              <div className="feature__row">
                <div className="feature-box">
                  <img
                    className="feature feature__1"
                    src={credit_card}
                    alt="Feature 1"
                  />
                    <h2 className="feature-box__text">Credit Cards</h2>
                </div>

                <div className="feature-box">
                  <img
                    className="feature feature__2"
                    src={investment}
                    alt="Feature 2"
                  />
                  
                    <h2 className="feature-box__text">
                      Lucrative Investments
                    </h2>
                  
                </div>

                <div className="feature-box">
                  <img
                    className="feature feature__3"
                    src={explore}
                    alt="Feature 3"
                  />
                  
                    <h2 className="feature-box__text">Best schemes & Plans</h2>
                
                </div>

                <div className="feature-box">
                  <img
                    className="feature feature__4"
                    src={help}
                    alt="Feature 4"
                  />
                  
                    <h2 className="feature-box__text">Seamless Support 24x7</h2>
                 
                </div>
              </div>
            </div>
          </section>

          <section className="accounts-section">
            <div className="account__property">
              <div className="account_property-div">
                <div className="account__property__box account__property__picture-box"></div>

                <div className="account__property__box account__property__text-box">
                  <h2 className="account__property__text__heading">
                    Get the best advice from top experts.
                  </h2>
                  <p className="account__property__text__information">
                    Here, at EarthID we have some of the greatest financial
                    experts in the market who are always willing to help you on
                    your financial path.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="aection-footer">
            <div className="footered">
              <div className="footer__text__box">
                <h2 className="footer__text">Connect with us</h2>
              </div>

              <div className="footer__icon__div">
                <div className="footer__icon__box">
                  <a
                    className="footer__icon "
                    href="https://twitter.com/earthid_ssi"
                    target="blank"
                  >
                    <img
                      className="footer__icon footer__icon__1"
                      src={twitter}
                      alt="Icon 1"
                    />
                  </a>
                </div>

                <div className="footer__icon__box">
                  <a
                    className="footer__icon "
                    href="https://www.facebook.com/myearth.id/"
                    target="blank"
                  >
                    <img
                      className="footer__icon__2"
                      src={facebook}
                      alt="Icon 2"
                    />
                  </a>
                </div>

                <div className="footer__icon__box">
                  <a
                    className="footer__icon "
                    href="https://www.linkedin.com/company/myearthid/"
                    target="blank"
                  >
                    <img
                      className="footer__icon_3"
                      src={linkedin}
                      alt="Icon 3"
                    />
                  </a>
                </div>

                <div className="footer__icon__box">
                  <a
                    className="footer__icon"
                    href="https://www.youtube.com/channel/UCaRjLyN4I_0UrK3NL4zU1Vg/featured"
                    target="blank"
                  >
                    <img
                      className="footer__icon_4"
                      src={youtube}
                      alt="Icon 4"
                    />
                  </a>
                </div>

                <div className="footer__icon__box">
                  <a
                    className="footer__icon"
                    href="https://www.instagram.com/myearthid/"
                    target="blank"
                  >
                    <img
                      className=" footer__icon__5"
                      src={instagram}
                      alt="Icon 5"
                    />
                  </a>
                </div>

                <div className="footer__icon__box">
                  <a className="footer__icon" href="" target="blank">
                    <img
                      className=" footer__icon__6"
                      src={mail}
                      alt="Icon 6"
                    />
                  </a>
                </div>
              </div>
            </div>
          </section>

    </>
  )
}

export default Baseui
